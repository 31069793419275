import { ToastContext } from 'design/contexts/Toast/Toast.context'
import { makeSafeVariants } from 'design/image-utils'
import { Form, Formik } from 'formik'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { useMutation } from 'react-query'
import * as Yup from 'yup'
import { CMS } from '~/cms/types'
import { trackEmailOptinModalSubmitted } from '~/analytics/events'
import { EmailOptinModalContext } from '~/experiments/JOIN-439-email-optin-modal-experiments/emailOptinModalContext'
import createCheckoutCart from '~/hooks/createCheckoutCart'
import { useSessionStorage } from '~/hooks/useStorage'
import {
  COOKIE_CHECKOUT_ID_KEY,
  SESSION_STORAGE_CHECKOUT_ID_KEY,
} from '~/routes/CheckoutFlow/constants'
import { startCheckoutMutator, useUTMParams } from '~/routes/Lander/hooks'
import { captureNetworkError } from '~/analytics/errors'
import { ModalEmailOptinUI } from './ModalEmailOptin.ui'
import { cleanJsonStrings } from '~/utils/unicode'
import useCmsVariant from '~/hooks/useCmsVariant'
import { EMAIL_SUBMITTED } from '~/routes/constants'
import { setCookie } from '~/utils/cookie'

const EmailInputSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please use a valid email')
    .required('Please type in your email address'),
})

export function ModalEmailOptin({
  closeFunction,
}: {
  closeFunction: (hasSubmitted?: boolean) => void
}) {
  const { pageData } = cleanJsonStrings(
    useStaticQuery<{
      pageData: CMS.ProtectedPage<CMS.ProductOfferLander>
    }>(graphql`
      query {
        pageData: contentfulProtectedPage(
          pageId: { eq: "signup-interstitial" }
        ) {
          ...ProtectedPage
        }
      }
    `)
  )
  const moduleOptInTopVariant = useCmsVariant<CMS.ModuleOptIn>(
    pageData.content.moduleOptInTop
  )
  const fireToast = React.useContext(ToastContext)
  const { ctaOrExitIntent } = React.useContext(EmailOptinModalContext)
  const queryParams = useUTMParams()

  const [, setCheckoutIdInSession] = useSessionStorage(
    SESSION_STORAGE_CHECKOUT_ID_KEY,
    null
  )
  const [, setEmailSubmittedInSession] = useSessionStorage(
    EMAIL_SUBMITTED,
    null
  )

  const [startCheckout] = useMutation(startCheckoutMutator, {
    onError: () => {
      fireToast('error', {
        children: 'Unable to save email at this time.',
      })
    },
    onSettled(_, e) {
      // never block a user from entering the signup flow in production
      if (process.env.NODE_ENV !== 'production' && !!e) {
        return
      }

      closeFunction(true)
    },
  })

  const [createCart] = createCheckoutCart()
  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true)
        setEmailSubmittedInSession(true)
        /**
         * We explicitly pass in an undefined $id value to handle for the case that the
         * user has a cached $id in the Klaviyo cookie from the authenticated experience.
         */
        window?._learnq?.push([
          'identify',
          { $email: values.email, $id: undefined },
        ])
        trackEmailOptinModalSubmitted(
          location.pathname,
          ctaOrExitIntent,
          values.email
        )
        createCart(values.email, {
          onError(e) {
            captureNetworkError(e)
          },
          onSuccess({ id }) {
            setCheckoutIdInSession(id)
            setCookie(COOKIE_CHECKOUT_ID_KEY, id)
          },
          onSettled() {
            startCheckout(
              { email: values.email, queryParams },
              {
                onError(e) {
                  captureNetworkError(e)
                },
                onSettled() {
                  actions.setSubmitting(false)
                },
              }
            )
          },
        })
      }}
      validationSchema={EmailInputSchema}
    >
      <Form title="ButcherBox email optin modal">
        <ModalEmailOptinUI
          closeFunction={closeFunction}
          imgOverlayBackground={makeSafeVariants({
            imageMobile: moduleOptInTopVariant?.imageFullWidthMobile,
            imageDesktop: moduleOptInTopVariant?.imageFullWidthDesktop,
          })}
        />
      </Form>
    </Formik>
  )
}
