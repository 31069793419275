import React from 'react'
import { BoxTypeDefinition } from '~/bb-api/schemata'
import { StagedSubscriptionContext } from '~/context/stagedSubscription'

const useDisplayBoxes = (
  unfilteredBoxTypes: BoxTypeDefinition[],
  basicBoxEnabled: boolean
) => {
  const { stagedSubscription } = React.useContext(StagedSubscriptionContext)
  return unfilteredBoxTypes
    .filter((boxType) => {
      return (
        boxType.isVisible ||
        ((basicBoxEnabled === true ||
          // In case someone gets into a state where basic box isn't enabled but they already have it selected
          stagedSubscription.box.type === 'basic_beef_chicken_pork') &&
          boxType.type === 'basic_beef_chicken_pork')
      )
    })
    .map((boxType) => {
      if (boxType.type === 'basic_beef_chicken_pork') {
        boxType.weight = 'Box size 7 - 7.5 lbs'
        boxType.isVisible = true
        boxType.badge = 'Only $99'
      }

      return boxType
    })
    .filter((x) => x.isVisible)
}

export default useDisplayBoxes
