import { graphql, useStaticQuery } from 'gatsby'

export const useGetPaymentImages = () => {
  const {
    testimonialOne,
    testimonialTwo,
    testimonialThree,
    testimonialFour,
  } = useStaticQuery(graphql`
    query {
      testimonialOne: file(relativePath: { eq: "testimonial-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 222) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

      testimonialTwo: file(relativePath: { eq: "testimonial-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 222) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

      testimonialThree: file(relativePath: { eq: "testimonial-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 222) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

      testimonialFour: file(relativePath: { eq: "testimonial-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 222) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return {
    testimonialOne,
    testimonialTwo,
    testimonialThree,
    testimonialFour,
  }
}
