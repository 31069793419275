import { Box, Grid } from '@chakra-ui/core'
import { rem } from 'design'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import { Body, H2 } from 'design/components/Typography/Typography'
import React from 'react'
import { BoxSizeCardRadio } from '~/routes/CheckoutFlow/BoxSizeCardRadio/BoxSizeCardRadio'
import type { BoxSizeFrequencyUIProps } from '~/routes/CheckoutFlow/BoxSizeFrequencyFrame/subcomponents/BoxSizeForm.types'
import { Frames } from '~/routes/CheckoutFlow/constants'
import { CheckoutLinkInternal } from '~/routes/CheckoutFlow/shared'
export const BoxSizeFormUI = ({
  heading,
  shippingText,
  boxSizes,
  isLoading,
}: BoxSizeFrequencyUIProps) => {
  return (
    <>
      <Box as="div" maxW={rem(490)} mt={rem(20)} mx="auto" textAlign="center">
        <H2>{heading}</H2>
        <Body>
          {shippingText}
          <strong>free!</strong>
        </Body>
      </Box>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Grid
          aria-label="Available Box Sizes"
          as="fieldset"
          gridGap={rem(24)}
          gridTemplateColumns={{
            base: '1fr',
            tablet: boxSizes.length === 1 ? '1fr' : '1fr 1fr',
          }}
          justifyItems="center"
          maxW={rem(712)}
          mx="auto"
          my={rem(32)}
        >
          {boxSizes.length ? (
            boxSizes.map((definition) => (
              <BoxSizeCardRadio
                badge={definition.size === 'big' ? 'Best Value' : null}
                definition={definition}
                key={definition.size}
              />
            ))
          ) : (
            <Body gridColumn="1 / -1">
              You must{' '}
              <CheckoutLinkInternal href={Frames.BoxTypeSelectionFrame}>
                select a plan
              </CheckoutLinkInternal>{' '}
              to pick your size.
            </Body>
          )}
        </Grid>
      )}
    </>
  )
}
