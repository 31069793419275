import { rem, styled } from 'design'
import { H2 } from '../Typography/Headlines'

export const CardRadioContainer = styled.div`
  background: white;
  border: 1px solid ${(p) => p.theme.colors.bb.silt};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: ${rem(343)};
  position: relative;
  width: 100%;

  &.selected {
    border-color: ${(p) => p.theme.colors.bb.spicedCrimson};
  }
`

export const CardRadioImage = styled.div`
  height: ${rem(129)};
`

export const CardRadioContent = styled.div`
  padding: ${rem(16)} ${rem(24)};
  min-height: ${rem(172)};
`

export const StyledCardRadioTitle = styled(H2)<{ htmlFor: string }>`
  display: flex;
  flex-direction: column;
  padding-bottom: ${rem(4)};
`
export const StyledRadioCardTitleContainer = styled.div<{
  hasMarginBottom?: boolean
}>`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(p) => (p.hasMarginBottom ? rem(8) : 0)};
  position: relative;
`

export const StyledCardRadioExpander = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0 ${rem(24)};
  width: 100%;

  &.expanded {
    padding-bottom: ${rem(16)};
    #plus-icon {
      transform: rotate(45deg);
    }
  }

  #plus-icon {
    transition: transform 75ms ease-in;
  }

  .details-content {
    padding-top: ${rem(16)};
  }
`

export const StyledBadgeContainer = styled.div<{
  horizontalPosition: 'left' | 'right'
  verticalPosition: 'top' | 'bottom'
}>`
  left: ${(p) => (p.horizontalPosition === 'left' ? '0' : '100%')};
  position: absolute;
  top: ${(p) => (p.verticalPosition === 'top' ? '0' : '100%')};
  transform: translateX(
      ${(p) => (p.horizontalPosition === 'left' ? '-20%' : '-85%')}
    )
    translateY(${(p) => (p.verticalPosition === 'top' ? '-20%' : '-85%')});
  z-index: 1;
`

export const StyledExpanderButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  list-style: none;
  user-select: none;
  width: calc(100% + ${rem(24 + 24)});
  padding: ${rem(16)} ${rem(24)};
  margin-left: ${rem(-24)};
  margin-right: ${rem(-24)};
`

export const StyledLearnMoreListParent = styled.ul<{ paddingTop?: string }>`
  list-style-type: none;
  padding-top: ${(p) => p.paddingTop || 'initial'};
`

export const StyledLearnMoreListElement = styled.li`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  &::before {
    content: '• ';
    color: ${(p) => p.theme.colors.bb.spicedCrimson};
    font-size: ${rem(12)};
    line-height: ${rem(24)};
    padding-right: ${rem(10)};
    vertical-align: text-bottom;
  }
`
