import { navigate } from 'gatsby'
import React from 'react'
import { CheckoutSummary } from '~/bb-api/schemata'
import useIsShopifyCheckoutEnabled from './useIsShopifyCheckoutEnabled'

/**
 * When a shopify cart is generated for a customer, navigate them away to that cart
 */
export default function useShopifyCheckoutNavigateEffect(
  checkoutSummary?: CheckoutSummary | null
) {
  const [shopifyCheckoutEnabled] = useIsShopifyCheckoutEnabled()

  React.useEffect(() => {
    if (!checkoutSummary) return
    if (
      shopifyCheckoutEnabled &&
      checkoutSummary.canCheckoutInShopify &&
      checkoutSummary.shopifyCheckoutUrl
    ) {
      navigate(checkoutSummary.shopifyCheckoutUrl, {
        /**
         * Replace the current URL in history (/plans-and-addons/checkout) with the Shopify cart URL
         * so that when a user hits the "back" button in their browser, they get taken back to
         * the page they were on before this one. We don't want someone coming back to this
         * checkout page because all it shows is a loader and then redirects back to Shopify,
         * which doesn't allow the user to go back through the checkout flow.
         */
        replace: true,
      })
    }
  }, [checkoutSummary, shopifyCheckoutEnabled])
}
