import BackgroundImage, { IBackgroundImageProps } from 'gatsby-background-image'
import { FluidObject } from 'gatsby-image'
import { device } from '~/styles/device'

/**
 * makeSafeVariants creates a valid argument for a GatsbyBackgroundImage fluid prop.
 *
 * This utility function circumvents the behavior of GatsbyBackgroundImage to throw an
 * error if a variant is missing when an array syntax is used to do responsive image styling.
 */
export const makeSafeVariants = ({
  imageMobile,
  imageDesktop,
}: {
  imageMobile: { fluid?: IBackgroundImageProps['fluid'] }
  imageDesktop: { fluid?: IBackgroundImageProps['fluid'] }
}): React.ComponentProps<typeof BackgroundImage>['fluid'] => {
  switch (true) {
    case Boolean(imageMobile?.fluid && imageDesktop?.fluid):
      return [
        imageMobile?.fluid,
        {
          ...(imageDesktop?.fluid || {}),
          media: device.tablet,
        },
      ] as FluidObject[]

    case Boolean(imageMobile?.fluid):
      return imageMobile.fluid
    case Boolean(imageDesktop?.fluid):
      return imageDesktop.fluid
    default:
      // Use a reasonable default that will not break gatsby-background-image
      return { src: '', srcSet: '', sizes: '', aspectRatio: 1 }
  }
}
