import React from 'react'
import { Testimonial } from '~/routes/CheckoutFlow/PaymentFrame/Testimonial'
import { useGetPaymentImages } from './useGetPaymentImages'
import { Carousel, Box, rem } from '@butcherbox/freezer'

export const TestimonialCarousel = () => {
  const {
    testimonialOne,
    testimonialTwo,
    testimonialThree,
    testimonialFour,
  } = useGetPaymentImages()

  return (
    <Box
      aria-label="Product Testimonials"
      background="white"
      m="auto"
      py={{ mobile: 24, tablet: 16 }}
    >
      <Box m="auto" p={16} style={{ maxWidth: rem(700) }}>
        <Carousel label="Testimonials">
          <Box component="li" p={8}>
            <Testimonial
              content="“The value that we get at ButcherBox is amazing. You can’t get
      this quality product at the store – and it comes right to your
      doorstep!”"
              heading="Karen &amp; Dawn"
              image={testimonialOne.childImageSharp.fluid}
              subheading="Members since 2016"
            />
          </Box>
          <Box component="li" p={8}>
            <Testimonial
              content="“You know these animals were treated well. You can really taste the difference.”"
              heading="Eric &amp; Nancy"
              image={testimonialFour.childImageSharp.fluid}
              subheading="Members since 2016"
            />
          </Box>
          <Box component="li" p={8}>
            <Testimonial
              content="“The taste is phenomenal, the product is great - there’s no reason to leave!”"
              heading="Greg &amp; Erick"
              image={testimonialTwo.childImageSharp.fluid}
              subheading="Members since 2016"
            />
          </Box>
          <Box component="li" p={8}>
            <Testimonial
              content="“Convenience, quality, good service - ButcherBox has it all!”"
              heading="Glenn"
              image={testimonialThree.childImageSharp.fluid}
              subheading="Member since 2015"
            />
          </Box>
        </Carousel>
      </Box>
    </Box>
  )
}
