import { RouteComponentProps } from '@reach/router'
import { LargeButtonPrimary } from 'design/components/Button/Button'
import { Text, Lockup, VisuallyHidden } from '@butcherbox/freezer'
import { Form } from 'formik'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import { MinimalPlanTypeCard } from '~/routes/CheckoutFlow/MinimalPlanTypeCard/MinimalPlanTypeCard'
import { PlanTypeSelectionPresentationProps } from '~/routes/CheckoutFlow/PlanTypeSelectionFrame/PlanTypeSelection.types'
import { NextFrameButtonContainer } from '~/routes/CheckoutFlow/shared'
import * as Styles from '~/routes/CheckoutFlow/PlanTypeSelectionFrame/PlanTypeSelection.css'

const PlanTypeSelectionUI = ({
  isSubmitting,
  values,
  planTypeData,
}: RouteComponentProps & PlanTypeSelectionPresentationProps) => {
  const buttonText = (function () {
    switch (values?.boxType) {
      case 'basic_beef_chicken_pork':
        return 'Next, confirm box size'
      case '':
        return 'Next'
      default:
        return 'Next, choose box size'
    }
  })()

  return (
    <div
      className={Styles.PlanTypeSelection}
      data-cy={TEST_ID.CHECKOUT_FLOW_BOX_TYPES}
    >
      <VisuallyHidden>
        <h1>ButcherBox Checkout: Plan Type Selection</h1>
      </VisuallyHidden>

      <Lockup className={Styles.Lockup}>
        <Text variant="H2Bold">Select your plan</Text>
        <Text variant="Body1Regular">
          Your Choice of <strong>100% Grass-Fed, Grass-Finished</strong> Beef;{' '}
          <strong>Free-Range Organic</strong> Chicken; Pork{' '}
          <strong>Raised Crate-Free</strong>; and <strong>Wild-Caught</strong>{' '}
          Seafood.
        </Text>
      </Lockup>

      <Form>
        <fieldset aria-label="Available Box Types" className={Styles.CardGrid}>
          {planTypeData.map((planType) => (
            <div
              className={Styles.PlanTypeCard}
              key={planType.typeDefinition.type}
            >
              <MinimalPlanTypeCard
                perkList={planType.perkList}
                typeDefinition={planType.typeDefinition}
              />
            </div>
          ))}
        </fieldset>

        <NextFrameButtonContainer>
          <LargeButtonPrimary
            data-cy={TEST_ID.CHECKOUT_FLOW_NEXT_FRAME}
            isDisabled={isSubmitting || values.boxType === ''}
            isLoading={isSubmitting}
            type="submit"
          >
            {buttonText}
          </LargeButtonPrimary>
        </NextFrameButtonContainer>
      </Form>
    </div>
  )
}

export default PlanTypeSelectionUI
