import React, { ReactElement } from 'react'
import { Box, Grid, GridProps } from '@chakra-ui/core'
import { rem } from 'design'
import { IconProps } from 'design/components/Icons/types'
import { H4 } from 'design/components/Typography/Typography'
import {
  IconProps as FreezerIconProps,
  IconClose,
  IconCheck,
  IconSuccessCircle,
  Color,
} from '@butcherbox/freezer'

export const List: React.FC<GridProps> = ({ children, ...props }) => {
  return (
    <Grid
      as="ul"
      columnGap={`0.5em`}
      data-what="unordered-list"
      gridTemplateColumns={`${rem(24)} 1fr`}
      listStyleType="none"
      rowGap={`1em`}
      {...props}
    >
      {children}
    </Grid>
  )
}

type ILIProps = {
  translateY?: number
  dataTestId?: string
  iconColor?: Color
  iconName: string
  renderIcon: () => ReactElement<FreezerIconProps>
} & Omit<IconProps, 'name'>

export type ILISimpleProps = Omit<
  ILIProps,
  'iconName' | 'translateY' | 'renderIcon'
>

export const LICircleCheck: React.FC<ILISimpleProps> = (props) => {
  return (
    <LI
      iconName="successCheckmarkCircle"
      renderIcon={() => (
        <IconSuccessCircle
          customColor={{ base: props.iconColor ?? 'spicedCrimson' }}
          pixelSize={24}
          size={'fixed'}
        />
      )}
      {...props}
    />
  )
}

export const LISimpleCheck: React.FC<ILISimpleProps> = (props) => (
  <LI
    iconName="successCheckmark"
    renderIcon={() => (
      <IconCheck customColor={{ base: 'spicedCrimson' }} size={'text'} />
    )}
    translateY={3}
    {...props}
  />
)

export const LISimpleX: React.FC<ILISimpleProps> = (props) => (
  <LI
    iconName="close"
    renderIcon={() => (
      <IconClose customColor={{ base: 'stone' }} size={'text'} />
    )}
    size={rem(14)}
    translateY={3}
    {...props}
  />
)

export const LI: React.FC<ILIProps> = ({
  children,
  dataTestId,
  renderIcon,
  ...props
}) => {
  const wrappedChildren =
    typeof children === 'string' ? <H4 as="span">{children}</H4> : children

  if (process.env.NODE_ENV !== 'production') {
    React.Children.only(wrappedChildren)
  }
  {
    /**
     * This `li` should be reworked to use `display: flex` to make icon alignment
     * consistent in all browsers (Safari currently displays the icons below the text)
     */
  }
  return (
    <Box as="li" data-testid={dataTestId} display="contents" {...props}>
      {renderIcon()}
      {wrappedChildren}
    </Box>
  )
}
