import { IconShipping } from '@butcherbox/freezer'
import { Box, Flex, Grid, VisuallyHidden } from '@chakra-ui/core'
import { RouteComponentProps } from '@reach/router'
import { rem, setA11yFocus } from 'design'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import { Body } from 'design/components/Typography/Typography'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import Cookies from 'js-cookie'
import React from 'react'
import { queryCache } from 'react-query'
import { captureNetworkError } from '~/analytics/errors'
import { trackCheckoutStepViewed } from '~/analytics/events'
import { segmentIdentify } from '~/analytics/identifiers'
import {
  CACHE_KEY_CHECKOUT_SETTINGS,
  CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS,
} from '~/bb-api/constants'
import { BoxType } from '~/bb-api/schemata'
import { TEST_ID } from '~/constants/cypress'
import { CheckoutStateContext } from '~/context/checkoutState'
import { StagedSubscriptionContext } from '~/context/stagedSubscription'
import createCheckoutCart from '~/hooks/createCheckoutCart'
import useBoxSizes from '~/hooks/useBoxSizes'
import useCheckoutSettings from '~/hooks/useCheckoutSettings'
import useOptimizelyExperiment from '~/hooks/useOptimizelyExperiment'
import { useSessionStorage } from '~/hooks/useStorage'
import ModalExitIntent from '~/routes/CheckoutFlow/ModalExitIntent'
import { BillingForm } from '~/routes/CheckoutFlow/PaymentFrame/BillingForm'
import { disclaimer } from '~/routes/CheckoutFlow/PaymentFrame/Disclaimer'
import { OrderSummary } from '~/routes/CheckoutFlow/PaymentFrame/OrderSummary'
import { PaymentContext } from '~/routes/CheckoutFlow/PaymentFrame/PaymentContext'
import { ShippingForm } from '~/routes/CheckoutFlow/PaymentFrame/ShippingForm'
import { PaymentState } from '~/routes/CheckoutFlow/PaymentFrame/types'
import {
  CHECKOUT_PAYMENT_STORAGE_KEY,
  COOKIE_OFFER_IDENTIFIER,
} from '~/routes/CheckoutFlow/constants'
import { usePageVisibility } from '~/routes/CheckoutFlow/hooks'
import { COOKIE_LANDER_COUPON_CODE } from '~/utils/lander'
import { TestimonialCarousel } from './TestimonialCarousel'
import { useCheckoutSummary } from './useCheckoutSummary'
import useIsShopifyCheckoutEnabled from './useIsShopifyCheckoutEnabled'
import useShopifyCheckoutNavigateEffect from './useShopifyCheckoutNavigateEffect'

const ID_TOS_CHECKBOX = 'tos-checkbox'

export type web_2876_variations = [
  'hide_carousel' | 'control',
  boolean,
  boolean
]

export default function PaymentFrame(_: RouteComponentProps) {
  const [variantDesktop] = useOptimizelyExperiment(
    'web-2876_social_proof_carousel_on_checkout__desktop_'
  ) as web_2876_variations
  const [variantMobile] = useOptimizelyExperiment(
    'web-2876_social_proof_carousel_on_checkout__mobile_'
  ) as web_2876_variations
  const variant = variantMobile ? variantMobile : variantDesktop

  const { isVisible, hasVisibilityChanged } = usePageVisibility()
  const fireToast = React.useContext(ToastContext)

  const [savedState, setSavedState] = useSessionStorage(
    CHECKOUT_PAYMENT_STORAGE_KEY,
    {}
  )

  const [state, setState] = React.useState<PaymentState>(savedState)
  const [shippingExpanded, setShippingExpanded] = React.useState(
    !(state as PaymentState).shipping?._hasSubmitted
  )
  const [billingExpanded, setBillingExpanded] = React.useState(
    !shippingExpanded ? !(state as PaymentState).billing?._hasSubmitted : false
  )
  const previousBillingExpanded = React.useRef(billingExpanded)
  const { stagedSubscription } = React.useContext(StagedSubscriptionContext)

  const { data: boxSizes = [] } = useBoxSizes(
    stagedSubscription.box.type as BoxType
  )
  const chosenBoxSizeDefinition = React.useMemo(
    () => boxSizes.find((x) => x.size === stagedSubscription.box.size),
    [boxSizes, stagedSubscription.box.size]
  )
  const { invoiceItems } = React.useContext(CheckoutStateContext)

  const calculatedSubtotal = React.useMemo(
    () =>
      (chosenBoxSizeDefinition?.price || 0) +
      stagedSubscription.box.addons.reduce(
        (sum, addon) => sum + addon.price * addon.quantity,
        0
      ) +
      (invoiceItems &&
        invoiceItems.reduce(
          (sum, invoiceItem) => sum + invoiceItem.price * invoiceItem.quantity,
          0
        )),
    [
      chosenBoxSizeDefinition?.price,
      invoiceItems,
      stagedSubscription.box.addons,
    ]
  )

  const { data: checkoutSettings } = useCheckoutSettings([
    CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS,
    {
      offerId: Cookies.get(COOKIE_OFFER_IDENTIFIER),
    },
  ])
  const [checkoutId, setCheckoutId] = React.useState(
    checkoutSettings?.checkoutId
  )

  const [isOrderSubmitting, setIsOrderSubmitting] = React.useState(false)

  const [createCart] = createCheckoutCart()

  const handleSetState = React.useCallback(
    (updater: PaymentState | ((arg: PaymentState) => PaymentState)) => {
      // this updates current React state
      setState(updater)

      // this updates session storage so data is retained if you move to a different page and back
      setSavedState(updater)
    },
    [setState, setSavedState]
  )

  const handleShippingFormSubmit = React.useCallback(
    // There is a possible race condition where the user might be able to send create cart again before the first createCart
    // has returned and saved to localStorage. We are going to accept condition this because we do not want to hold up the checkout
    // For any non-essential reason.
    async (values: PaymentState['shipping']) => {
      handleSetState(
        (prevState) =>
          ({
            ...prevState,
            email: values?.email,
            phoneNumber: values?.phoneNumber,
            shipping: { ...values, _hasSubmitted: true },
          } as PaymentState)
      )

      setShippingExpanded(false)

      if (!state.billing?._hasSubmitted) setBillingExpanded(true)

      checkoutId === null &&
        createCart(values?.email, {
          onSuccess(response) {
            setCheckoutId(response.id)
          },
          onError(e) {
            captureNetworkError(e)
          },
        })

      segmentIdentify({
        traits: {
          email: values?.email,
          first_name: values?.firstName,
          last_name: values?.lastName,
        },
      })
      // update partially-identified anonymous profile with more data

      queryCache.invalidateQueries(CACHE_KEY_CHECKOUT_SETTINGS)
    },
    [checkoutId, createCart, handleSetState, setCheckoutId, state]
  )

  const handleBillingFormSubmit = React.useCallback(
    async (values: PaymentState['billing']) => {
      handleSetState(
        (prevState) =>
          ({
            ...prevState,
            billing: { ...values, _hasSubmitted: true },
          } as PaymentState)
      )

      //we don't want it to set the focus on the checkbox when there is a payment error so voiceover finishes reading the toast
      if (
        previousBillingExpanded.current !== billingExpanded &&
        billingExpanded === true
      ) {
        setA11yFocus(document.getElementById(ID_TOS_CHECKBOX) as HTMLElement)
      }
      setBillingExpanded(false)
      previousBillingExpanded.current = false
    },
    [billingExpanded, handleSetState]
  )

  const [
    shouldRefreshStripeToken,
    setShouldRefreshStripeToken,
  ] = React.useState(false)

  /**
   * This triggers an update of state, which Order Summary picks up on and mutates
   * checkout summary. If the discount is not accepted, a toast will be fired saying so.
   */
  const handleUpdateDiscountCode = React.useCallback(
    (code: string) => {
      handleSetState((prevState) => ({ ...prevState, discountCode: code }))
    },
    [handleSetState]
  )

  React.useEffect(() => {
    if (checkoutId) trackCheckoutStepViewed(checkoutId, 4)
    // should only be run on first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (isVisible && hasVisibilityChanged === true) {
      const codeCookie = Cookies.get(COOKIE_LANDER_COUPON_CODE)
      codeCookie && handleUpdateDiscountCode(codeCookie)
    }
  }, [handleUpdateDiscountCode, hasVisibilityChanged, isVisible])

  const prevDiscountCode = React.useRef<string | null>(null)
  const { extras } = React.useContext(CheckoutStateContext)
  const couponCode =
    state.discountCode || checkoutSettings?.discountCode || null

  const [isShopifyCheckoutEnabled] = useIsShopifyCheckoutEnabled()

  const [
    updateCheckoutSummary,
    { data: summary },
    checkoutSummaryFetchCount,
  ] = useCheckoutSummary(
    state,
    checkoutSettings,
    prevDiscountCode,
    handleUpdateDiscountCode,
    fireToast
  )

  React.useEffect(() => {
    updateCheckoutSummary({
      couponCode,
      extras,
      invoiceItems,
      state,
      subscription: stagedSubscription,
      isShopifyCheckoutEnabled,
    })
  }, [
    couponCode,
    extras,
    invoiceItems,
    state,
    updateCheckoutSummary,
    stagedSubscription,
    isShopifyCheckoutEnabled,
  ])

  // potentially navigate someone to a Shopify checkout instead
  useShopifyCheckoutNavigateEffect(summary)

  // wait on the first checkout summary request to finish only if it might contain a Shopify
  // checkout URL
  const shouldAwaitCheckoutSummaryRequest = isShopifyCheckoutEnabled
    ? checkoutSummaryFetchCount === 0
    : false

  const ready =
    stagedSubscription &&
    stagedSubscription.box.size &&
    stagedSubscription.box.type &&
    // if the user might be redirected to Shopify, wait on that
    !shouldAwaitCheckoutSummaryRequest &&
    // if the user will be redirected on shopify, show the loading spinner until they do
    !summary?.shopifyCheckoutUrl

  /**
   * Break into container and presentational component
   * https://nbox-341.atlassian.net/browse/WEB-2654
   */
  return ready ? (
    <>
      <Box
        boxSizing="content-box"
        data-cy={TEST_ID.CHECKOUT_FLOW_PAYMENT}
        maxW={rem(1144)}
        mx="auto"
        p={{ base: rem(16), tablet: rem(24) }}
      >
        <VisuallyHidden>
          <h1>ButcherBox Checkout: Enter Payment Information</h1>
        </VisuallyHidden>

        <Flex align="center" as={Body} justify="center" mb={rem(16)}>
          <IconShipping
            customColor={{ base: 'slate', accent: 'spicedCrimson' }}
            marginRight={12}
            size={'small'}
          />
          <strong>
            <Box as="span" color="bb.spicedCrimson">
              FREE Carbon Neutral Shipping
            </Box>
          </strong>
        </Flex>

        <PaymentContext.Provider value={state}>
          <Grid
            columnGap={rem(8)}
            gridTemplateColumns={{
              mobile: '1fr',
              desktop: `1fr ${rem(352)}`,
            }}
            mb={rem(30)}
            rowGap={rem(32)}
          >
            <Box>
              <Box>
                <ShippingForm
                  isActive={shippingExpanded}
                  makeActive={() => setShippingExpanded(true)}
                  onSubmit={handleShippingFormSubmit}
                  updateDiscountCode={handleUpdateDiscountCode}
                />
              </Box>

              <Box>
                <BillingForm
                  calculatedSubtotal={calculatedSubtotal}
                  isActive={billingExpanded}
                  makeActive={() => setBillingExpanded(true)}
                  onSubmit={handleBillingFormSubmit}
                  setShouldRefreshStripeToken={setShouldRefreshStripeToken}
                  shouldRefreshStripeToken={shouldRefreshStripeToken}
                />
              </Box>

              {React.cloneElement(disclaimer, {
                d: { base: 'none', desktop: 'block' },
                mt: rem(50),
              })}
            </Box>

            <OrderSummary
              boxPrice={chosenBoxSizeDefinition?.price ?? 0}
              calculatedSubtotal={calculatedSubtotal}
              setIsOrderSubmitting={setIsOrderSubmitting}
              setShouldRefreshStripeToken={setShouldRefreshStripeToken}
              summary={summary}
            />

            {React.cloneElement(disclaimer, {
              d: { base: 'block', desktop: 'none' },
            })}
          </Grid>
        </PaymentContext.Provider>
      </Box>
      {variant !== 'hide_carousel' ? <TestimonialCarousel /> : null}
      <ModalExitIntent isOrderSubmitting={isOrderSubmitting} />
    </>
  ) : (
    <LoadingSpinner />
  )
}
