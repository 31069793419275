import { useMutation } from 'react-query'
import { CREATE_CHECKOUT_CART } from '~/bb-api/endpoints'
import { CheckoutCartData } from '~/bb-api/schemata'
import axios from '~/utils/axios'

const createCheckoutCart = () => {
  return useMutation((email: string) =>
    axios
      .post<CheckoutCartData>(CREATE_CHECKOUT_CART, { email: email })
      .then((response) => response.data)
  )
}

export default createCheckoutCart
