import { Box, VisuallyHidden } from '@butcherbox/freezer'
import { useLocation } from '@reach/router'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import { frameData } from '~/routes/CheckoutFlow/frameData/frameData'
import * as Styles from '~/routes/CheckoutFlow/ProgressBar/ProgressBar.css'

export const ProgressBarFillLevel = () => {
  const location = useLocation()
  const pathname = location?.pathname

  const currentFrameData = frameData(pathname)

  const stepTotal = currentFrameData ? currentFrameData.totalFrames : 6
  const currentStep = currentFrameData ? currentFrameData.frameNumber : 0
  const progressWidth = currentStep === 0 ? 16 : (currentStep / stepTotal) * 100
  const cappedProgressWidth = progressWidth > 100 ? 100 : progressWidth

  return (
    <>
      <Box
        className={Styles.Indicator}
        data-cy={TEST_ID.CHECKOUT_PROGRESS_BAR}
        style={{ width: cappedProgressWidth ? `${cappedProgressWidth}%` : 0 }}
      />
      <VisuallyHidden>
        {/* only display if currentFrameData is defined to prevent giving inaccurate information */}
        {currentFrameData && `On checkout step ${currentStep} of ${stepTotal}`}
      </VisuallyHidden>
    </>
  )
}
