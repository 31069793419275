import { RouteComponentProps } from '@reach/router'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import { Formik } from 'formik'
import React from 'react'
import * as yup from 'yup'
import { trackError } from '~/analytics/errors'
import {
  trackCheckoutStepCompleted,
  trackCheckoutStepViewed,
  trackPlanChosen,
} from '~/analytics/events'
import { BoxTypeDefinition, NewSubscription } from '~/bb-api/schemata'
import { CheckoutStateContext } from '~/context/checkoutState'
import { StagedSubscriptionContext } from '~/context/stagedSubscription'
import mutateCheckoutCart from '~/hooks/mutateCheckoutCart'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'
import { useSessionStorage } from '~/hooks/useStorage'
import {
  Frames,
  SESSION_STORAGE_CHECKOUT_ID_KEY,
} from '~/routes/CheckoutFlow/constants'
import * as MINIMALFIXTURE from '~/routes/CheckoutFlow/MinimalPlanTypeCard/MinimalPlanTypeCard.fixture'
import useDisplayBoxes from '~/routes/CheckoutFlow/PlanTypeSelectionFrame/hooks/useDisplayBoxes'
import PlanTypeSelectionUI from '~/routes/CheckoutFlow/PlanTypeSelectionFrame/PlanTypeSelection.UI'
import { curatedMeatGroupSet } from '~/routes/CheckoutFlow/types'

const PlanTypeSelection = ({
  navigate,
  startingWithBasic,
  unfilteredBoxes,
}: RouteComponentProps & {
  startingWithBasic?: boolean
  unfilteredBoxes: BoxTypeDefinition[]
}) => {
  const showToast = React.useContext(ToastContext)

  const { stagedSubscription, updateStagedSubscription } = React.useContext(
    StagedSubscriptionContext
  )
  const { invoiceItems } = React.useContext(CheckoutStateContext)
  const [basicBoxEnabled] = useOptimizelyFeature('basic_box_in_checkout')
  const [sessionCheckoutId] = useSessionStorage(
    SESSION_STORAGE_CHECKOUT_ID_KEY,
    null
  )

  const [mutateCart] = mutateCheckoutCart('BoxTypeSelectionFrame')

  const checkoutId = sessionCheckoutId

  //if the visitor started with basic box from a previous visit, we want to give them the option to manage it in checkout
  const shouldDisplayBasicBox = startingWithBasic && basicBoxEnabled

  const filteredBoxes = useDisplayBoxes(unfilteredBoxes, shouldDisplayBasicBox)

  React.useEffect(() => {
    if (checkoutId) trackCheckoutStepViewed(checkoutId, 1)
    // should only be run on first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = React.useCallback(
    async (values) => {
      const stagedNewSubscription = stagedSubscription as NewSubscription
      const matchingDefinition = filteredBoxes.find(
        (x) => x.type === values.boxType
      )
      // should never happen, but just in case
      if (!matchingDefinition) {
        return showToast('warning', {
          children: 'Please select one of the available box types.',
        })
      }

      //We need to save this value so we can pass it to the update cart endpoint
      const updatedNewStagedSubscription = {
        ...stagedNewSubscription,
        box: {
          ...stagedNewSubscription.box,
          items:
            matchingDefinition.type === 'cst'
              ? stagedNewSubscription.box.items
              : [],
          name: matchingDefinition.name,
          type: matchingDefinition.type,
        },
      }

      updateStagedSubscription(updatedNewStagedSubscription)

      if (!curatedMeatGroupSet.has(matchingDefinition.type)) {
        trackPlanChosen(matchingDefinition.type, matchingDefinition.name)
      }

      if (checkoutId) {
        trackCheckoutStepCompleted({ checkout_id: checkoutId, step: 1 })

        //update checkout
        mutateCart({
          invoiceItems,
          subscription: updatedNewStagedSubscription,
        })
      }

      if (navigate) {
        navigate(Frames.BoxSizeFrequencyFrame)
      } else {
        trackError((scope) => {
          scope.capture(
            new Error(
              'navigate function not available. Failed to navigate away from BoxTypeSelectionFrame.'
            )
          )
        })
      }
    },
    [
      stagedSubscription,
      filteredBoxes,
      updateStagedSubscription,
      checkoutId,
      showToast,
      mutateCart,
      invoiceItems,
      navigate,
    ]
  )

  const initialValues = {
    boxType:
      stagedSubscription.box.type ||
      filteredBoxes.find((x) => x.isDefault)?.type,
  }

  const SCHEMA = yup.object().shape({
    boxType: yup.string().required('You must select a box type to continue.'),
  })

  const availableBoxTypes = new Set(filteredBoxes.map((box) => box.type))
  const experimentFixture = MINIMALFIXTURE.MinimalPlanTypeCard
  const availablePlanTypes = experimentFixture
    .map((planType) => {
      /* Handle edge case for people who have already selected a curated meat group
    We overwrite the default fixture data for curated so that the rendered curated card
    radio input matches their selection (passed in on the initial values) and will appear 
    selected if they navigate back to this screen */
      if (
        curatedMeatGroupSet.has(planType.typeDefinition.type) &&
        curatedMeatGroupSet.has(initialValues.boxType)
      ) {
        planType.typeDefinition.type = initialValues.boxType
      }
      return planType
    })
    .filter((planType) => {
      if (availableBoxTypes.has(planType.typeDefinition.type)) {
        return true
      }

      return false
    })

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={SCHEMA}
    >
      {({ isSubmitting, values }) => (
        <PlanTypeSelectionUI
          isSubmitting={isSubmitting}
          planTypeData={availablePlanTypes}
          values={values}
        />
      )}
    </Formik>
  )
}

export default PlanTypeSelection
