import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import Cookies from 'js-cookie'
import React from 'react'
import {
  datadogDealsAssistiveData,
  datadogDealsPageStates,
  trackDataDogDealsRedirect,
  trackInvoiceItemsAddedToCheckoutCart,
  trackProductListViewed,
} from '~/analytics/events'
import { CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS } from '~/bb-api/constants'
import { CheckoutStateContext } from '~/context/checkoutState'
import { StagedSubscriptionContext } from '~/context/stagedSubscription'

import mutateCheckoutCart from '~/hooks/mutateCheckoutCart'
import useCheckoutDeals from '~/hooks/useCheckoutDeals'
import useCheckoutSettings from '~/hooks/useCheckoutSettings'
import { useSessionStorage } from '~/hooks/useStorage'
import { InvoiceItemMap } from '~/routes/CheckoutFlow/MemberDealsFrame/MemberDealsFrame.types'
import MemberDealsFrameUI from '~/routes/CheckoutFlow/MemberDealsFrame/MemberDealsFrame.ui'
import {
  COOKIE_OFFER_IDENTIFIER,
  Frames,
  SESSION_STORAGE_CHECKOUT_ID_KEY,
} from '~/routes/CheckoutFlow/constants'

const MemberDealsFrame: React.FC<RouteComponentProps> = () => {
  const { data: checkoutSettings } = useCheckoutSettings([
    CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS,
    {
      offerId: Cookies.get(COOKIE_OFFER_IDENTIFIER),
    },
  ])
  const [sessionCheckoutId] = useSessionStorage(
    SESSION_STORAGE_CHECKOUT_ID_KEY,
    null
  )
  const checkoutId = sessionCheckoutId || checkoutSettings?.checkoutId

  const hastrackedProductViewed = React.useRef(false)

  const { stagedSubscription } = React.useContext(StagedSubscriptionContext)

  const shouldSkipAddons =
    stagedSubscription?.box?.addons.length > 0 ? false : true

  const {
    incrementInvoiceItem,
    decrementInvoiceItem,
    invoiceItems,
  } = React.useContext(CheckoutStateContext)

  const { data: dealsToDisplay, isLoading, isError } = useCheckoutDeals()

  //we're transforming the data to match the authenticated view's "upcomingOrderMap" so the deal card can potentially be used in both places
  const invoiceItemMap = React.useMemo(() => {
    return invoiceItems
      ? invoiceItems.reduce<InvoiceItemMap>((obj, product) => {
          obj[product.sku] = product
          return obj
        }, {})
      : {}
  }, [invoiceItems])

  const [mutateCart] = mutateCheckoutCart('DealsFrame')

  const handleTracking = React.useCallback(() => {
    if (checkoutId) {
      mutateCart({
        subscription: stagedSubscription,
        invoiceItems: invoiceItems,
      })
    }
    if (invoiceItems) {
      trackInvoiceItemsAddedToCheckoutCart(stagedSubscription, invoiceItems)
    }
  }, [checkoutId, invoiceItems, mutateCart, stagedSubscription])

  React.useEffect(() => {
    /**
     * We want to fire `Product List Viewed` only once, and only when the user navigates
     * the product list into the viewport.
     */
    if (!isLoading && !hastrackedProductViewed.current) {
      trackProductListViewed({
        orderedProducts: dealsToDisplay,
        productCategory: 'memberDeal',
        productListCategory: 'memberDeal-checkoutDealsFrame',
      })
      hastrackedProductViewed.current = true
    }
  }, [dealsToDisplay, isLoading])

  const hasSentDealsRedirectEvent = React.useRef<boolean>(false)
  //borrowed from addons redirect to payment frame if no member deals available
  React.useEffect(() => {
    if (isLoading) return

    if (!dealsToDisplay?.length || isError) {
      const datadogDealsAssistiveData: datadogDealsAssistiveData = {
        dealsToDisplayLength: dealsToDisplay?.length,
        dealsEnabled: true,
        dealsIntendedForDisplay: dealsToDisplay,
      }

      // Only want to send event once if this useEffect fires multiple times
      if (!hasSentDealsRedirectEvent.current) {
        trackDataDogDealsRedirect(
          datadogDealsPageStates?.REDIRECT,
          datadogDealsAssistiveData
        )
        hasSentDealsRedirectEvent.current = true
      }
      navigate(Frames.PaymentFrame)
    }
  }, [dealsToDisplay, shouldSkipAddons, isLoading, isError])

  const memberDealsButtonText = shouldSkipAddons
    ? 'Proceed To Checkout'
    : 'Next, Select Add-ons'

  return (
    <MemberDealsFrameUI
      buttonText={memberDealsButtonText}
      deals={dealsToDisplay}
      decrementInvoiceItem={decrementInvoiceItem}
      handleTracking={handleTracking}
      incrementInvoiceItem={incrementInvoiceItem}
      invoiceItemMap={invoiceItemMap}
      isLoading={isLoading}
      nextFrame={Frames.PaymentFrame}
    />
  )
}
export default MemberDealsFrame
