import React from 'react'
import DealCardUI from '~/routes/CheckoutFlow/MemberDealsFrame/DealCard/DealCard.ui'
import { DealsCard } from '~/routes/CheckoutFlow/MemberDealsFrame/DealCard/DealCard.types'
import useVisibleProduct from '~/hooks/useVisibleProduct'

export default function DealCard({
  deal,
  decrementInvoiceItem,
  incrementInvoiceItem,
  invoiceItemMap,
  displayIndex,
}: DealsCard) {
  const quantity = invoiceItemMap[deal.sku]
    ? invoiceItemMap[deal.sku].quantity
    : 0

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const incrementSku = React.useCallback(
    incrementInvoiceItem(deal, quantity, displayIndex),
    [incrementInvoiceItem, deal, quantity, displayIndex]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const decrementSku = React.useCallback(
    decrementInvoiceItem(deal, quantity, displayIndex),
    [decrementInvoiceItem, deal, quantity, displayIndex]
  )

  const dealCardRef = useVisibleProduct(deal, { threshold: 0.5 })

  return (
    <DealCardUI
      deal={deal}
      decrementSku={decrementSku}
      incrementSku={incrementSku}
      quantity={quantity}
      ref={dealCardRef}
    />
  )
}
