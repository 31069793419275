import { Box } from '@chakra-ui/core'
import dayjs from 'dayjs'
import { rem } from 'design'
import { ButtonPrimary } from 'design/components/Button/Button'
import Form from 'design/components/Form/Form'
import Input from 'design/components/Input/Input'
import Select from 'design/components/Select/Select'
import {
  Body,
  CTA,
  H2,
  H5,
  LinkButton,
} from 'design/components/Typography/Typography'
import { Field, Formik } from 'formik'
import { graphql, useStaticQuery } from 'gatsby'
import Cookies from 'js-cookie'
import React from 'react'
import * as Yup from 'yup'
import { CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS } from '~/bb-api/constants'
import {
  FormikValues as ShippingFormValues,
  ShippingAddressForm,
} from '~/components/FormShippingAddress'
import useCheckoutSettings from '~/hooks/useCheckoutSettings'
import {
  backupShippingWindowText,
  COOKIE_OFFER_IDENTIFIER,
  MOBILE_X_PADDING,
  TABLET_X_PADDING,
} from '~/routes/CheckoutFlow/constants'
import { Header } from '~/routes/CheckoutFlow/PaymentFrame/Header'
import { PaymentContext } from '~/routes/CheckoutFlow/PaymentFrame/PaymentContext'
import { formatDiscountCode } from '~/routes/CheckoutFlow/PaymentFrame/utils'
import { cleanJsonStrings } from '~/utils/unicode'

const DISCOUNT_FORM_SCHEMA = Yup.object().shape({
  discountCode: Yup.string(),
})

export const ShippingForm = ({
  isActive,
  makeActive,
  onSubmit,
  updateDiscountCode,
}: {
  isActive: boolean
  makeActive: () => void
  onSubmit: (values: ShippingFormValues) => Promise<any>
  updateDiscountCode: (code: string) => void
}) => {
  const state = React.useContext(PaymentContext)
  const { email, phoneNumber, shipping } = state
  const { data: { shipmentWeeks = [] } = {} } = useCheckoutSettings([
    CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS,
    {
      offerId: Cookies.get(COOKIE_OFFER_IDENTIFIER),
    },
  ])
  const [hasBeenClosed, setHasBeenClosed] = React.useState(false)

  const data = cleanJsonStrings(
    useStaticQuery(graphql`
      query {
        pageData: contentfulMicrocopy(
          copyId: { eq: "checkoutPaymentFormShippingWindow" }
        ) {
          ...Microcopy
        }
      }
    `)
  )

  const shippingWindowCopy = data?.pageData?.copy
  const shippingWindowText: string =
    shippingWindowCopy && shippingWindowCopy?.length <= 40
      ? shippingWindowCopy
      : backupShippingWindowText

  const options = React.useMemo<
    React.ComponentProps<typeof Select>['options']
  >(() => {
    return shipmentWeeks.map((date) => ({
      label: `Week of ${dayjs(date).format('MMM D, YYYY')}`,
      value: date,
    }))
  }, [shipmentWeeks])

  const schema = React.useMemo(() => {
    return shipmentWeeks.length
      ? { shipmentWeek: Yup.string().required('A shipment week is required') }
      : undefined
  }, [shipmentWeeks])

  React.useEffect(() => {
    if (!isActive && !hasBeenClosed) {
      setHasBeenClosed(true)
    }
  }, [isActive, hasBeenClosed])

  return (
    <Box
      bg="bb.silt"
      border="1px solid"
      borderColor="bb.granite"
      data-where="shipping-form"
      pos="relative"
    >
      <Header align="left" direction="column" h={rem(72)} justify="center">
        <H2>Shipping Info</H2>
        <H5 as="h3" pt={rem(2)}>
          {process.env.IS_STORYBOOK ? 'Ships in 1-4 days' : shippingWindowText}
        </H5>
      </Header>

      {isActive ? (
        <Box
          bg="white"
          px={{ base: rem(MOBILE_X_PADDING), tablet: rem(TABLET_X_PADDING) }}
          py={rem(20)}
        >
          <ShippingAddressForm
            AdditionalElements={[
              shipmentWeeks.length ? (
                <Field
                  component={Select}
                  floatLabel
                  gridColumn={{ base: '1 / -1', tablet: '1 / span 2' }}
                  label="Select Shipment Week"
                  name="shipmentWeek"
                  options={options}
                />
              ) : null,

              <Formik
                initialValues={{ discountCode: state.discountCode || '' }}
                key="discount"
                onSubmit={(values) =>
                  Promise.resolve(updateDiscountCode(values.discountCode))
                }
                validationSchema={DISCOUNT_FORM_SCHEMA}
              >
                {({ isSubmitting }) => (
                  <Form
                    alignItems="flex-start"
                    autoComplete="off"
                    d="flex"
                    gridColumn={{ base: '1 / -1', tablet: '3 / span 2' }}
                    id="checkout-discount-code"
                    title="Enter discount code form"
                  >
                    <Field
                      component={Input}
                      data-public
                      floatLabel
                      handleOnChange={formatDiscountCode}
                      label="Discount Code"
                      name="discountCode"
                    />

                    <ButtonPrimary
                      flexShrink={0}
                      // @ts-ignore
                      form="checkout-discount-code"
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                      ml={rem(-1)}
                      px={rem(25)}
                      type="submit"
                    >
                      Apply
                    </ButtonPrimary>
                  </Form>
                )}
              </Formik>,
            ]}
            additionalElementsSchema={schema}
            centerAlignButtonArea
            collectContactInformation
            onSave={onSubmit}
            seedValues={shipping}
            shouldAutoFocus={hasBeenClosed}
            submitButtonContent="Next"
          />
        </Box>
      ) : (
        <>
          <LinkButton
            aria-label="Edit Shipping Information"
            onClick={makeActive}
            pos="absolute"
            right={rem(24)}
            top={rem(11)}
          >
            <CTA>Edit</CTA>
          </LinkButton>
          <Body
            data-dd-privacy="mask"
            data-private
            pb={rem(16)}
            px={{ base: rem(MOBILE_X_PADDING), tablet: rem(TABLET_X_PADDING) }}
          >
            {shipping?.firstName} {shipping?.lastName}
            <br />
            Email: {email}
            <br />
            Phone:{' '}
            {phoneNumber
              // remove non-digits to normalize for next step
              ?.replace(/\D/g, '')
              // given a 10-11 digit string, reformat it into XXX-XXX-XXXX
              .replace(/^(1?)(\d{3})(\d{3})(\d{4})$/, '$2-$3-$4')}
            <br />
            {shipping?.addressLineOne}
            <br />
            {shipping?.addressLineTwo && <br />}
            {shipping?.city}, {shipping?.state} {shipping?.postalCode}
            <br />
            Delivery Note: {shipping?.deliveryInstructions || 'n/a'}
            {shipping?.shipmentWeek ? (
              <>
                <br />
                Shipping: Week of{' '}
                {dayjs(shipping?.shipmentWeek).format('MMM D, YYYY')}
              </>
            ) : null}
          </Body>
        </>
      )}
    </Box>
  )
}
