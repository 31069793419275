import { rem } from 'design'
import {
  CardRadioFormik,
  CardRadioTitle,
  SelectableRadioComponent,
} from 'design/components/CardRadio/CardRadioComponents'
import {
  CardRadioContent,
  StyledBadgeContainer,
  StyledLearnMoreListElement,
  StyledLearnMoreListParent,
  StyledRadioCardTitleContainer,
} from 'design/components/CardRadio/CardRadio.styles'
import { Body } from 'design/components/Typography/Typography'
import { Field, FieldProps } from 'formik'
import React from 'react'
import { BoxSize } from '~/bb-api/schemata'
import CardBadge from 'design/components/CardBadge/CardBadge'

export interface BoxSizeCardRadioUIProps extends FieldProps<any> {
  badge: string
  bulletText: string
  description: string
  formattedPrice: string
  size: BoxSize
  humanizedSizeName: string
}

const BoxSizeCardRadioUIComponent = ({
  badge,
  bulletText,
  description,
  formattedPrice,
  humanizedSizeName,
  field,
  form,
  ...props
}: BoxSizeCardRadioUIProps) => {
  const id = `${field?.value}-radio`

  return (
    <CardRadioFormik field={field} {...props}>
      {badge ? (
        <StyledBadgeContainer
          horizontalPosition="right"
          verticalPosition="bottom"
        >
          <CardBadge>{badge}</CardBadge>
        </StyledBadgeContainer>
      ) : null}
      <CardRadioContent>
        <StyledRadioCardTitleContainer>
          <CardRadioTitle
            radioId={id}
            secondaryText={formattedPrice}
            title={humanizedSizeName}
          />
          <SelectableRadioComponent
            data-what={id}
            data-where="box-size-selection"
            field={field}
            form={form}
            id={id}
          />
        </StyledRadioCardTitleContainer>
        {description}
        <StyledLearnMoreListParent paddingTop={rem(13)}>
          <StyledLearnMoreListElement>
            <Body>{bulletText}</Body>
          </StyledLearnMoreListElement>
        </StyledLearnMoreListParent>
      </CardRadioContent>
    </CardRadioFormik>
  )
}

export const BoxSizeCardRadioUI = (
  props: Omit<BoxSizeCardRadioUIProps, 'field' | 'meta' | 'form'>
) => {
  return (
    <Field
      component={BoxSizeCardRadioUIComponent}
      name="size"
      type="radio"
      value={props?.size}
      {...props}
    />
  )
}
