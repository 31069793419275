import { Box } from '@chakra-ui/core'
import { Router, useLocation } from '@reach/router'
import { css, Global } from 'design'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import { ShowToast } from 'design/contexts/Toast/Toast.context'
import { graphql, useStaticQuery } from 'gatsby'
import qs from 'querystringify'
import React from 'react'
import {
  BoxTypeDefinition,
  CheckoutCartData,
  CheckoutSettings,
} from '~/bb-api/schemata'
import useProtectedPage from '~/cms/hooks/useProtectedPage'
import ErrorBoundary from '~/components/ErrorBoundary/ErrorBoundary'
import { TEST_ID } from '~/constants/cypress'
import {
  CheckoutStateContext,
  CheckoutStateProvider,
} from '~/context/checkoutState'
import { NewStagedSubscriptionProvider } from '~/context/stagedSubscription'
import { ViewedProductsProvider } from '~/context/viewedProducts'
import {
  JOIN_397_VARIANTS,
  JOIN_397_VARIANT_TYPES,
} from '~/experiments/JOIN-397__remove_footer_in_signup_flow/RemoveFooterExperiment.types'
import { EmailOptinModalProvider } from '~/experiments/JOIN-439-email-optin-modal-experiments/emailOptinModalContext'
import useCheckoutPromise, {
  CHECKOUT_FETCH_STATE,
} from '~/hooks/useCheckoutPromise'
import {
  CHECKOUT_ACTION_STATE,
  useCheckoutStateActions,
} from '~/hooks/useCheckoutStateActions'
import useOptimizelyExperiment from '~/hooks/useOptimizelyExperiment'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'
import useSwitchToSubDomain from '~/hooks/useSwitchToSubDomain'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import BoxSizeFrequencyFrame from '~/routes/CheckoutFlow/BoxSizeFrequencyFrame/BoxSizeFrequencyFrame'
import { Frames } from '~/routes/CheckoutFlow/constants'
import { CuratedMeatTypeSelectionFrame } from '~/routes/CheckoutFlow/CuratedMeatTypeSelectionFrame/CuratedMeatTypeSelectionFrame'
import CustomizeBoxFrame from '~/routes/CheckoutFlow/CustomizeBoxFrame'
import MemberDealsFrame from '~/routes/CheckoutFlow/MemberDealsFrame/MemberDealsFrame'
import PaymentFrame from '~/routes/CheckoutFlow/PaymentFrame/PaymentFrame'
import BoxTypeSelectionFrame from '~/routes/CheckoutFlow/PlanTypeSelectionFrame/PlanTypeSelection'
import { ProgressBar } from '~/routes/CheckoutFlow/ProgressBar/ProgressBar'
import { cleanJsonStrings } from '~/utils/unicode'

export default function CheckoutFlow(props) {
  const [switchToSubDomain] = useOptimizelyFeature(
    'stay-1043-switch-to-sub-domains'
  )
  useSwitchToSubDomain()
  const data = cleanJsonStrings(
    useStaticQuery(graphql`
      query {
        pageData: contentfulProtectedPage(pageId: { eq: "plans-and-addons" }) {
          ...ProtectedPage
        }
      }
    `)
  )
  const location = useLocation()
  const params = qs.parse(location.search) as {
    cart?: string
    coupon?: string
    exclusive_offer?: string
  }

  const cartId = params.cart || null //TODO: check for session storage
  const {
    boxTypes,
    settings,
    cartData,
    checkoutStatus,
    checkedCookies,
  } = useCheckoutPromise({
    cartId,
    params,
  })
  useProtectedPage(data?.pageData, null, {
    useCheckoutSettings: true,
  })
  if (switchToSubDomain) {
    const path = window.location.pathname ?? ''
    const searchParams = window.location.search ?? ''
    window.location.replace(`https://buy.butcherbox.com${path}${searchParams}`)
  }
  return (
    <NewStagedSubscriptionProvider>
      <CheckoutStateProvider>
        <CheckoutFlowFrames
          boxTypes={boxTypes}
          cartData={cartData}
          checkedCookies={checkedCookies}
          checkoutStatus={checkoutStatus}
          params={params}
          settings={settings}
          state={props?.location?.state}
        />
      </CheckoutStateProvider>
    </NewStagedSubscriptionProvider>
  )
}

type CheckoutFlowFramesProps = {
  checkoutStatus: string
  boxTypes: BoxTypeDefinition[]
  settings: CheckoutSettings
  cartData: CheckoutCartData
  checkedCookies: boolean
  params: {
    cart?: string
    coupon?: string
    exclusive_offer?: string
  }
  state: {
    /**
     * toast allows a page to display a custom toast message at the start of checkout
     */
    toast: Parameters<ShowToast>
  }
}

const CheckoutFlowFrames = ({
  state,
  boxTypes,
  settings,
  cartData,
  checkedCookies,
  checkoutStatus,
  params,
}: CheckoutFlowFramesProps) => {
  const { initialBoxType } = React.useContext(CheckoutStateContext)

  const checkoutActionStatus = useCheckoutStateActions(
    state,
    checkoutStatus,
    boxTypes,
    settings,
    cartData,
    params,
    checkedCookies
  )

  const [shouldRemoveFooter] = useOptimizelyExperiment(
    'join-397__removing_footer_in_signup_flow'
  ) as [JOIN_397_VARIANT_TYPES, null, null]

  return (
    <UnauthenticatedLayout
      checkoutHeader
      hasSettings={checkoutStatus === CHECKOUT_FETCH_STATE.SUCCESS}
      runCheckoutValidation={
        checkoutActionStatus === CHECKOUT_ACTION_STATE.DONE
      }
      shouldRemoveFooter={shouldRemoveFooter}
    >
      <ProgressBar
        mounted={checkoutActionStatus === CHECKOUT_ACTION_STATE.DONE}
      />
      {checkoutStatus === CHECKOUT_FETCH_STATE.SUCCESS ? (
        <EmailOptinModalProvider>
          <ViewedProductsProvider>
            <Box
              bg="bb.ivory"
              data-cy={TEST_ID.CHECKOUT_FLOW}
              minHeight={
                shouldRemoveFooter !== JOIN_397_VARIANTS.CONTROL
                  ? // calculating full viewport height, minus the legal footer and header heights
                    'calc(100vh - (76px + 148px))'
                  : null
              }
            >
              <Router primary={false}>
                <BoxTypeSelectionFrame
                  default
                  path={Frames.BoxTypeSelectionFrame}
                  startingWithBasic={
                    initialBoxType === 'basic_beef_chicken_pork'
                  }
                  unfilteredBoxes={boxTypes}
                />

                <BoxSizeFrequencyFrame path={Frames.BoxSizeFrequencyFrame} />

                <CuratedMeatTypeSelectionFrame
                  boxTypes={boxTypes}
                  path={Frames.CuratedMeatTypeSelectionFrame}
                  shouldShowAllBeef={initialBoxType === 'all_beef'}
                />

                <CustomizeBoxFrame path={Frames.CustomizeBoxFrame} />
                <MemberDealsFrame path={Frames.DealsFrame} />
                <PaymentFrame path={Frames.PaymentFrame} />
              </Router>

              {checkoutActionStatus === CHECKOUT_ACTION_STATE.ERROR && (
                <ErrorBoundary />
              )}
              {/* Hide Zendesk in checkout */}
              {
                <Global
                  styles={css`
                    iframe#launcher {
                      display: none;
                    }
                  `}
                />
              }
            </Box>
          </ViewedProductsProvider>
        </EmailOptinModalProvider>
      ) : (
        <LoadingSpinner />
      )}
    </UnauthenticatedLayout>
  )
}
