import { styled } from 'design'
import Cookies from 'js-cookie'
import React from 'react'
import { trackError } from '~/analytics/errors'
import { trackExitIntentInteraction } from '~/analytics/events'
import { CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS } from '~/bb-api/constants'
import { CheckoutStateContext } from '~/context/checkoutState'
import useCheckoutSettings from '~/hooks/useCheckoutSettings'
import useExitIntent from '~/hooks/useExitIntent'
import { useLocalStorage } from '~/hooks/useStorage'
import { COOKIE_OFFER_IDENTIFIER } from './constants'
import {
  Box,
  Modal,
  ModalBody,
  rem,
  Text,
  Button,
  Lockup,
} from '@butcherbox/freezer'
import * as Styles from './ModalExitIntent.css'

const ModalExitIntent = ({
  isOrderSubmitting,
}: {
  isOrderSubmitting: boolean
}) => {
  const { data: checkoutSettings } = useCheckoutSettings([
    CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS,
    {
      offerId: Cookies.get(COOKIE_OFFER_IDENTIFIER),
    },
  ])

  const { addExtra, extras } = React.useContext(CheckoutStateContext)
  const existingExtra = extras.find(
    (el) => el.sku === checkoutSettings?.exitIntentSettings?.product.sku
  )

  const [showModal, setShowModal] = React.useState(false)
  const [hasModalBeenShown, setHasModalBeenShown] = useLocalStorage(
    'exit_modal_shown',
    false
  )
  const [exitIntentDetected, resetExitIntent] = useExitIntent({
    enabled: !hasModalBeenShown || !existingExtra,
    triggerOnPageBlur: true,
  })

  React.useEffect(() => {
    if (
      !hasModalBeenShown &&
      !existingExtra &&
      exitIntentDetected &&
      !isOrderSubmitting
    ) {
      setShowModal(true)
      setHasModalBeenShown(true)
      resetExitIntent()
    }
  }, [
    existingExtra,
    exitIntentDetected,
    hasModalBeenShown,
    resetExitIntent,
    setHasModalBeenShown,
    isOrderSubmitting,
  ])

  const handleModalClose = React.useCallback(
    (status: Parameters<typeof trackExitIntentInteraction>[0]) => {
      setShowModal(false)
      if (checkoutSettings?.exitIntentSettings) {
        trackExitIntentInteraction(
          status,
          checkoutSettings?.exitIntentSettings?.product.sku,
          checkoutSettings?.exitIntentSettings?.product.description
        )
      } else {
        trackError((scope) => {
          scope.capture(
            new Error(
              'ExitIntentSettings not available on CheckoutSettings. Failed to track Exit Intent Interaction.'
            )
          )
        })
      }
    },
    [setShowModal, checkoutSettings?.exitIntentSettings]
  )

  const addExitIntentExtra = React.useCallback(() => {
    if (!checkoutSettings?.exitIntentSettings) return
    addExtra(checkoutSettings?.exitIntentSettings?.product?.sku, 1)
    handleModalClose('accept')
  }, [addExtra, checkoutSettings?.exitIntentSettings, handleModalClose])

  return showModal ? (
    <Modal onClose={() => handleModalClose('decline')} size="fit" title="">
      <Box className={Styles.ModalBodyContainer}>
        <ModalContentBackground
          backgroundImage={checkoutSettings?.exitIntentSettings?.image ?? ''}
        >
          <Box>
            <Lockup className={Styles.ContentContainer}>
              <Text color="spicedCrimson" variant="DisplayOne">
                {checkoutSettings?.exitIntentSettings?.heading}
              </Text>
              <Text variant="BodyH2Bold">
                <span
                  dangerouslySetInnerHTML={{
                    __html: checkoutSettings?.exitIntentSettings?.description,
                  }}
                />
              </Text>
              <Box display="flex" flexDirection="column">
                <Button onClick={addExitIntentExtra} variant="primary">
                  {checkoutSettings?.exitIntentSettings?.buttonText}
                </Button>
                <Button
                  color="spicedCrimson"
                  marginTop={16}
                  onClick={() => handleModalClose('decline')}
                  variant="secondary"
                >
                  {checkoutSettings?.exitIntentSettings?.footer}
                </Button>
              </Box>
            </Lockup>
          </Box>
        </ModalContentBackground>
      </Box>
    </Modal>
  ) : null
}

const ModalContentBackground = styled(ModalBody)<{ backgroundImage: string }>`
  background: transparent;
  padding: ${rem(25)} ${rem(0)};
  &::before {
    background-color: white;
    background-image: url(${(p) => p.backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    bottom: ${rem(-60)};
    content: '';
    height: ${rem(343)};
    width: ${rem(342)};
    position: absolute;
    transform: rotate(90deg);
    z-index: -1;
  }
  ${(p) => p.theme.media.tablet} {
    padding: ${rem(40)} ${rem(96)};
    &::before {
      right: ${rem(-130)};
      width: 70%;
      top: ${rem(-66)};
      transform: rotate(30deg);
      height: 110%;
      z-index: -1;
    }
  }
`

export default ModalExitIntent
