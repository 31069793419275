import { Box } from '@chakra-ui/core'
import { rem } from 'design'
import { CTA, H2, LinkButton } from 'design/components/Typography/Typography'
import React from 'react'
import { NewSubscriptionBox, User } from '~/bb-api/schemata'
import FormBillingInformation from '~/components/FormBilling'
import { StagedSubscriptionContext } from '~/context/stagedSubscription'
import {
  MOBILE_X_PADDING,
  TABLET_X_PADDING,
} from '~/routes/CheckoutFlow/constants'
import { Header } from '~/routes/CheckoutFlow/PaymentFrame/Header'
import { PaymentContext } from '~/routes/CheckoutFlow/PaymentFrame/PaymentContext'
import { IBillingForm } from '~/routes/CheckoutFlow/PaymentFrame/types'
import { normalizeBoxName } from '~/utils/boxVariants'

export const BillingForm = ({
  calculatedSubtotal,
  isActive,
  makeActive,
  onSubmit,
  shouldRefreshStripeToken,
  setShouldRefreshStripeToken,
}: IBillingForm) => {
  /**
   * hasBeenActive is a mechanism to keep the form loaded so the stripe
   * fields don't lose their state as you move throughout the page
   */
  const [hasBeenActive, setHasBeenActive] = React.useState(false)
  const { billing, shipping } = React.useContext(PaymentContext)
  const { stagedSubscription } = React.useContext(StagedSubscriptionContext)

  React.useEffect(() => {
    if (!hasBeenActive && isActive) setHasBeenActive(true)
  }, [hasBeenActive, isActive])

  return (
    <Box
      bg="bb.silt"
      border="1px solid"
      borderColor="bb.granite"
      data-where="billing-form"
      mt={rem(8)}
      pos="relative"
    >
      <Header>
        <H2>Billing Info</H2>
      </Header>

      {isActive || hasBeenActive ? (
        <Box
          bg="white"
          d={!isActive ? 'none' : 'block'}
          px={{ base: rem(MOBILE_X_PADDING), tablet: rem(TABLET_X_PADDING) }}
          py={rem(20)}
        >
          <FormBillingInformation
            centerAlignButtonArea
            onSave={onSubmit}
            paymentRequestTitle={`ButcherBox: ${normalizeBoxName(
              stagedSubscription.box as Required<NewSubscriptionBox>
            )}`}
            paymentRequestTotalPrice={calculatedSubtotal}
            requireStripeActivation
            seedValues={shipping}
            setShouldRefreshStripeToken={setShouldRefreshStripeToken}
            shippingAddress={shipping as User['shippingAddress']}
            shouldAutoFocus
            shouldRefreshStripeToken={shouldRefreshStripeToken}
            showCreditCardFieldActive
            showPaymentRequestButton
            submitButtonText="Next"
          />
        </Box>
      ) : null}

      {!isActive && billing ? (
        <>
          <LinkButton
            aria-label="Edit Billing Information"
            onClick={makeActive}
            pos="absolute"
            right={rem(24)}
            top={rem(11)}
          >
            <CTA>Edit</CTA>
          </LinkButton>
          <Box
            data-dd-privacy="mask"
            data-private
            pb={rem(16)}
            px={{ base: rem(MOBILE_X_PADDING), tablet: rem(TABLET_X_PADDING) }}
          >
            {billing.firstName} {billing.lastName}
            <br />
            {billing.addressLineOne}
            <br />
            {billing.addressLineTwo ? (
              <>
                {billing.addressLineTwo} <br />
              </>
            ) : null}
            {billing.city}, {billing.state} {billing.postalCode}
          </Box>
        </>
      ) : null}
    </Box>
  )
}
