import Cookies from 'js-cookie'
import { useMutation } from 'react-query'
import { UPDATE_CHECKOUT_CART } from '~/bb-api/endpoints'
import { CheckoutCartData } from '~/bb-api/schemata'
import {
  COOKIE_COUPON_CODE,
  COOKIE_OFFER_IDENTIFIER,
  Frames,
  getSubsequentFrame,
  SESSION_STORAGE_CHECKOUT_ID_KEY,
} from '~/routes/CheckoutFlow/constants'
import axios from '~/utils/axios'
import { captureNetworkError } from '~/analytics/errors'
import useAnonymousId from './useAnonymousId'
import { useSessionStorage } from './useStorage'

const mutateCheckoutCart = (checkoutFrame: keyof typeof Frames) => {
  const { anonymousId } = useAnonymousId()
  const [sessionCheckoutId] = useSessionStorage(
    SESSION_STORAGE_CHECKOUT_ID_KEY,
    null
  )
  const cartId = sessionCheckoutId

  return useMutation(
    (checkoutCartData: CheckoutCartData) => {
      const reclamationFrame = getSubsequentFrame(
        checkoutFrame,
        checkoutCartData.subscription.box.type
      )
      if (cartId) {
        const cartIdString = `?cart=${cartId}`

        // We set the anonymous id to ajs_aid because Segment will automatically
        // use it to identify a user on pageload
        // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/querystring/
        const idString = anonymousId ? `&ajs_aid=${anonymousId}` : ''

        /**
         * WEB-1496: Workaround for Cart Reclamation w/ Offers and Coupons
         *
         * Offers and Coupons are saved in cookies. This means we can not guarantee
         * that they can be reclaimed by future customers.
         *
         * In order to guarantee this, we are adding the values to the reclamation URL.
         *
         */
        const offerCookie = Cookies.get(COOKIE_OFFER_IDENTIFIER)
        const couponCookie = Cookies.get(COOKIE_COUPON_CODE)
        const offerString = offerCookie ? `&exclusive_offer=${offerCookie}` : ''
        const couponString = couponCookie ? `&coupon=${couponCookie}` : ''

        const reclamationUrl = `https://butcherbox.com${reclamationFrame}${cartIdString}${idString}${offerString}${couponString}`

        // extract only required properties to reduce the potential of submitting special characters to the api
        const cleanInvoiceItems = checkoutCartData.invoiceItems.map(
          ({ sku, quantity }) => {
            return { sku, quantity }
          }
        )
        return axios
          .put<CheckoutCartData>(UPDATE_CHECKOUT_CART(cartId), {
            subscription: {
              box: checkoutCartData.subscription.box,
              interval: checkoutCartData.subscription.interval,
            },
            invoiceItems: cleanInvoiceItems,
            reclamationUrl,
            lastFrameCompleted: checkoutFrame,
          })
          .then((response) => response.data)
      } else {
        return Promise.reject()
      }
    },
    {
      onError(e) {
        captureNetworkError(e)
      },
    }
  )
}

export default mutateCheckoutCart
