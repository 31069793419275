import { rem, styled } from 'design'
import { CardRadioContent } from 'design/components/CardRadio/CardRadio.styles'
import {
  CardRadioFormik,
  SelectableRadioComponent,
} from 'design/components/CardRadio/CardRadioComponents'
import { H2 } from 'design/components/Typography/Typography'
import { Field, FieldProps } from 'formik'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import type { CuratedMeatGroup } from '~/routes/CheckoutFlow/types'

export interface MeatGroupCardUIProps extends FieldProps<any> {
  meatGroup: CuratedMeatGroup
  title: string
  icon: React.ReactChild
}

const MeatGroupCardUIComponent = ({
  title,
  icon,
  field,
  form,
}: MeatGroupCardUIProps) => {
  const id = `${field?.value}_radio`

  return (
    <CardRadioFormik
      data-cy={TEST_ID.CHECKOUT_FLOW_CURATED_MEAT_CARD}
      data-cy-checkout-meat-type={title}
      field={field}
    >
      <CardRadioContent>
        <MeatGroupCardFlexContainer>
          <MeatGroupImageContainer>{icon}</MeatGroupImageContainer>
          <StyledLabel htmlFor={id}>
            <H2 as="span" paddingBottom={rem(16)}>
              {title}
            </H2>
          </StyledLabel>
          <RadioPositionContainer>
            <SelectableRadioComponent
              data-what={id}
              data-where="curated-meat-selection"
              field={field}
              form={form}
              id={id}
            />
          </RadioPositionContainer>
        </MeatGroupCardFlexContainer>
      </CardRadioContent>
    </CardRadioFormik>
  )
}

export const MeatGroupCardUI = (
  props: Omit<MeatGroupCardUIProps, 'field' | 'meta' | 'form'>
) => {
  return (
    <Field
      component={MeatGroupCardUIComponent}
      name="meatGroup"
      type="radio"
      value={props.meatGroup}
      {...props}
    />
  )
}

const MeatGroupCardFlexContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`

const RadioPositionContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

const MeatGroupImageContainer = styled.div`
  margin-top: ${rem(11)};
`

const StyledLabel = styled.label`
  cursor: inherit;
  padding-bottom: ${rem(16)};
`
