import { Text } from '@butcherbox/freezer'
import { Box } from '@chakra-ui/core'
import { rem } from 'design'
import { Field } from 'formik'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import { BoxFrequencyFormProps } from '~/routes/CheckoutFlow/BoxSizeFrequencyFrame/BoxSizeFrequency.types'
import { boxFrequencyValues } from '~/routes/CheckoutFlow/BoxSizeFrequencyFrame/BoxSizeFrequencyFrame.constants'
import { RadioWithLabel } from '~/routes/CheckoutFlow/BoxSizeFrequencyFrame/subcomponents/RadioWithLabel'
import { humanizeBoxFrequency } from '~/utils/humanize'

export const BoxFrequencyForm = ({ values }: BoxFrequencyFormProps) => {
  return (
    <Box maxW={rem(490)} mx="auto" my={rem(32)} textAlign="center">
      <Text variant="H2Bold">How often?</Text>
      <Text variant="Body1Regular">
        <>
          <>We'll send you a box </>
          <strong
            // @ts-ignore
            css={{ textTransform: 'lowercase' }}
            data-cy={TEST_ID.CHECKOUT_FLOW_CURRENT_FREQUENCY_SELECTION}
          >
            {humanizeBoxFrequency(values.interval)}.
          </strong>
          <br />
          (You can pause or cancel anytime.)
        </>
      </Text>

      <Box
        aria-label="Available Delivery Frequencies"
        as="fieldset"
        bg="white"
        border="1px solid"
        borderColor="bb.silt"
        data-cy={TEST_ID.CHECKOUT_FLOW_FREQUENCY_FIELDSET}
        mt={rem(24)}
        px={rem(16)}
        py={rem(22)}
      >
        {boxFrequencyValues.map((frequency) => {
          const frequencyFocusChange = {
            ...frequency,
            inputProps: {
              ...frequency.inputProps,
              autoFocus: false,
            },
          }

          return (
            <Field
              component={RadioWithLabel}
              data-cy-box-frequency={frequency.value}
              inputProps={frequencyFocusChange.inputProps}
              key={frequency.value}
              label={frequency.label}
              name="interval"
              type="radio"
              value={frequency.value}
            />
          )
        })}
      </Box>
    </Box>
  )
}
