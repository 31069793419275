import { Box, VisuallyHidden } from '@chakra-ui/core'
import { RouteComponentProps } from '@reach/router'
import { rem } from 'design'
import { LargeButtonPrimary } from 'design/components/Button/Button'
import { Form, Formik } from 'formik'
import Cookies from 'js-cookie'
import React from 'react'
import * as yup from 'yup'
import { trackError } from '~/analytics/errors'
import {
  trackBoxSizeChosen,
  trackCheckoutStepCompleted,
  trackCheckoutStepViewed,
  trackPlanPricesSeen,
  trackSignupBoxFrequencyChosen,
} from '~/analytics/events'
import { CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS } from '~/bb-api/constants'
import type { BoxType, NewSubscription } from '~/bb-api/schemata'
import { TEST_ID } from '~/constants/cypress'
import { CheckoutStateContext } from '~/context/checkoutState'
import { StagedSubscriptionContext } from '~/context/stagedSubscription'
import mutateCheckoutCart from '~/hooks/mutateCheckoutCart'
import useBoxSizes from '~/hooks/useBoxSizes'
import useCheckoutSettings from '~/hooks/useCheckoutSettings'
import { useSessionStorage } from '~/hooks/useStorage'
import type { BoxSizeFrequencyValues } from '~/routes/CheckoutFlow/BoxSizeFrequencyFrame/BoxSizeFrequency.types'
import { useNextButtonProps } from '~/routes/CheckoutFlow/BoxSizeFrequencyFrame/hooks/useNextButtonProps'
import { BoxFrequencyForm } from '~/routes/CheckoutFlow/BoxSizeFrequencyFrame/subcomponents/BoxFrequencyForm.ui'
import { BoxSizeFormUI } from '~/routes/CheckoutFlow/BoxSizeFrequencyFrame/subcomponents/BoxSizeForm.ui'
import {
  COOKIE_OFFER_IDENTIFIER,
  Frames,
  SESSION_STORAGE_CHECKOUT_ID_KEY,
} from '~/routes/CheckoutFlow/constants'
import { NextFrameButtonContainer } from '~/routes/CheckoutFlow/shared'
import { curatedMeatGroupSet } from '~/routes/CheckoutFlow/types'

const SCHEMA = yup.object().shape({
  interval: yup.string().required('You must select a frequency to continue.'),
  size: yup.string().required('You must select a box type to continue.'),
})

const BoxSizeFrequencyFrame: React.FC<RouteComponentProps> = ({ navigate }) => {
  const [pricesSeen, setPricesSeen] = React.useState(false)

  const { stagedSubscription, updateStagedSubscription } = React.useContext(
    StagedSubscriptionContext
  )
  const { invoiceItems } = React.useContext(CheckoutStateContext)

  const { data: boxSizes = [], isLoading: sizesLoading } = useBoxSizes(
    stagedSubscription.box.type as BoxType
  )
  const { data: checkoutSettings } = useCheckoutSettings([
    CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS,
    {
      offerId: Cookies.get(COOKIE_OFFER_IDENTIFIER),
    },
  ])
  const [sessionCheckoutId] = useSessionStorage(
    SESSION_STORAGE_CHECKOUT_ID_KEY,
    null
  )
  const [mutateCart] = mutateCheckoutCart('BoxSizeFrequencyFrame')
  const isLoading = sizesLoading
  // creating this bool to conditionally update language when there is only one size
  const isOnlySingleBoxSizeAvailable = boxSizes.length === 1
  const checkoutId = sessionCheckoutId || checkoutSettings?.checkoutId
  const { heading, shippingText } = isOnlySingleBoxSizeAvailable
    ? {
        heading: 'Your box size',
        shippingText: 'Shipping is always ',
      }
    : {
        heading: 'Choose your box size',
        shippingText: 'Either size, shipping is always ',
      }

  const { nextFrame, continueButtonText } = useNextButtonProps(
    stagedSubscription.box.type
  )

  const initialValues = {
    interval:
      stagedSubscription.interval ||
      checkoutSettings?.defaultFrequency ||
      'every_month',
    size: stagedSubscription.box.size || boxSizes[0]?.size || '',
  } as BoxSizeFrequencyValues

  React.useEffect(() => {
    if (checkoutId) trackCheckoutStepViewed(checkoutId, 2)
    // should only be run on first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Tracks the plan priced seen
  React.useEffect(() => {
    if (!sizesLoading && !pricesSeen) {
      trackPlanPricesSeen(
        boxSizes.map((boxSize) => {
          return {
            size: boxSize.size,
            type: stagedSubscription?.box.type,
            price: boxSize.price,
          }
        }),
        Frames.BoxSizeFrequencyFrame
      )
      setPricesSeen(true)
    }
  }, [boxSizes, sizesLoading, stagedSubscription.box, pricesSeen])

  const handleSubmit = React.useCallback(
    async (values: BoxSizeFrequencyValues) => {
      const stagedNewSubscription = stagedSubscription as NewSubscription
      //We need to save this value so we can pass it to the update cart endpoint
      const updatedNewStagedSubscription = {
        ...stagedNewSubscription,
        box: {
          ...stagedNewSubscription.box,
          items:
            values.size !== stagedNewSubscription.box.size
              ? []
              : stagedNewSubscription.box.items,
          size: values.size,
        },
        interval: values.interval,
      }

      updateStagedSubscription(updatedNewStagedSubscription)

      trackBoxSizeChosen(values.size)
      trackSignupBoxFrequencyChosen(values.interval)

      const isCurated = curatedMeatGroupSet.has(stagedSubscription.box.type)

      if (
        stagedSubscription.box.type !== 'cst' &&
        !isCurated &&
        checkoutId !== null
      ) {
        trackCheckoutStepCompleted({ checkout_id: checkoutId, step: 2 })
      }

      mutateCart({
        invoiceItems,
        subscription: updatedNewStagedSubscription,
      })

      if (navigate) {
        navigate(nextFrame)
      } else {
        trackError((scope) => {
          scope.capture(
            new Error(
              'navigate function not available. Failed to navigate away from BoxSizeFrequencyFrame.'
            )
          )
        })
      }
    },
    [
      stagedSubscription,
      updateStagedSubscription,
      checkoutId,
      mutateCart,
      invoiceItems,
      navigate,
      nextFrame,
    ]
  )

  return (
    <Box data-cy={TEST_ID.CHECKOUT_FLOW_BOX_SIZES} pb={rem(100)} px={rem(16)}>
      <VisuallyHidden>
        <h1>ButcherBox Checkout: Box Size and Frequency Selection</h1>
      </VisuallyHidden>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={SCHEMA}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <BoxSizeFormUI
              boxSizes={boxSizes}
              heading={heading}
              isLoading={isLoading}
              shippingText={shippingText}
            />
            <BoxFrequencyForm values={values} />
            <NextFrameButtonContainer>
              <LargeButtonPrimary
                data-cy={TEST_ID.CHECKOUT_FLOW_NEXT_FRAME}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                type="submit"
              >
                {continueButtonText}
              </LargeButtonPrimary>
            </NextFrameButtonContainer>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default BoxSizeFrequencyFrame
