import { APIException } from '~/bb-api/schemata'

/**
 * Get the first error from an APIException, has some built
 * in default behavior depending on Exception "shape".
 */
export function getFirstErrorFromAPI(exception?: APIException): string {
  const generalMessage =
    'We apologize but an unexpected error occurred, please contact customer service.'

  if (!exception?.message && !exception?.errors) {
    return generalMessage
  }

  const errors = getAPIErrors(exception)

  const parentheticalError = () => {
    if (errors && errors.length > 0) {
      return errors[0] !== exception.message ? ' (' + errors[0] + ')' : ''
    } else {
      return
    }
  }

  return (exception.message || generalMessage) + parentheticalError()
}

/**
 * Checks if an APIException contains a search string.
 * Could be in the general exception message or in any
 * of the errors returned.
 */
export function containsErrorFromAPI(
  exception: APIException,
  search: string
): boolean {
  if (exception.message?.toLowerCase().includes(search)) {
    return true
  }

  return getAPIErrors(exception).some((e) => e.toLowerCase().includes(search))
}

/**
 * Get all the APIException errors flattened to a single array.
 */
export function getAPIErrors(exception: APIException): Array<string> {
  if (!exception.errors) {
    return []
  }

  if (typeof exception.errors === 'string') {
    return [exception.errors]
  }

  return (
    (exception.errors &&
      Object.keys(exception.errors)
        .map(function (key) {
          return Array.isArray(exception.errors[key])
            ? exception.errors[key].flat()
            : exception.errors[key]
        })
        .flat()) ||
    []
  )
}
