import useBoxTypes from '~/hooks/useBoxTypes'
import React from 'react'
import { useQuery } from 'react-query'
import {
  CACHE_KEY_CHECKOUT_CART,
  CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS,
} from '~/bb-api/constants'
import { GET_CHECKOUT_CART } from '~/bb-api/endpoints'
import { SavedCartSubscription } from '~/bb-api/schemata'
import {
  COOKIE_COUPON_CODE,
  COOKIE_OFFER_IDENTIFIER,
} from '~/routes/CheckoutFlow/constants'
import axios from '~/utils/axios'
import { setCookie } from '~/utils/cookie'
import useCheckoutSettings from './useCheckoutSettings'
import Cookies from 'js-cookie'

export enum CHECKOUT_FETCH_STATE {
  SUCCESS = 'success',
  LOADING = 'loading',
  ERROR = 'error',
}

export default function useCheckoutPromise({
  cartId,
  params,
}: {
  cartId: string
  params: {
    cart?: string
    coupon?: string
    exclusive_offer?: string
  }
}) {
  const checkCookies = () => {
    if (params.exclusive_offer) {
      setCookie(COOKIE_OFFER_IDENTIFIER, params.exclusive_offer)
    }

    if (params.coupon) {
      setCookie(COOKIE_COUPON_CODE, params.coupon)
    }
  }
  //we need to set the exclusive_offer cookie if it has not already been set on a previous page and is detected in the url
  checkCookies()

  const { status: boxTypeStatus, data: boxTypesData } = useBoxTypes()
  const {
    status: checkoutStatus,
    data: checkoutSettings,
  } = useCheckoutSettings([
    CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS,
    {
      offerId: params.exclusive_offer || Cookies.get(COOKIE_OFFER_IDENTIFIER),
    },
  ])

  const { status: cartStatus, data: cartData } = useQuery(
    CACHE_KEY_CHECKOUT_CART,
    () =>
      cartId
        ? axios
            .get<SavedCartSubscription>(GET_CHECKOUT_CART(cartId))
            .then((res) => res.data)
            .catch((error) => {
              console.log('getCart error', error)
              return null
            })
        : Promise.resolve(), //cartId does not exist for a brand new checkout lead, so we return a resolved promise in its absense so we can check the status against the other fetches below
    { staleTime: Infinity } //staleTime: Infinity means it won't keep trying to re-fetch data and potentially hang checkout
  )

  //returns appropriate string status depending on status of all fetches combined
  const allStatus = React.useCallback(() => {
    const allStatusArr = [
      boxTypeStatus,
      checkoutStatus,
      cartStatus,
    ] as Array<string>

    if (allStatusArr.every((x) => x === CHECKOUT_FETCH_STATE.SUCCESS)) {
      //if each is a success, return the status "success"
      return CHECKOUT_FETCH_STATE.SUCCESS
    } else if (
      allStatusArr.findIndex((x) => x === CHECKOUT_FETCH_STATE.LOADING) !== -1
    ) {
      //if at least one is still in a loading state, return "loading"
      return CHECKOUT_FETCH_STATE.LOADING
    } else {
      //otherwise, return a failed error state
      return CHECKOUT_FETCH_STATE.ERROR
    }
  }, [checkoutStatus, cartStatus, boxTypeStatus])
  return {
    boxTypes: boxTypesData,
    settings: checkoutSettings,
    cartData: cartData,
    checkoutStatus: allStatus(),
    checkedCookies: true,
  }
}
