import { shortId } from 'design'
import React from 'react'

import {
  Box,
  Card,
  CardAction,
  CardBadge,
  CardBody,
  CardMedia,
  CardPrice,
  CardQuantity,
  IconCheck,
  Text,
} from '@butcherbox/freezer'
import CardQuantityInput from 'design/components/CardQuantityInput/CardQuantityInput'
import type { DealsCardUI } from '~/routes/CheckoutFlow/MemberDealsFrame/DealCard/DealCard.types'
import { formatPriceFromCents } from '~/utils'
import makeCardBadgeProps, { isValidBadge } from '~/utils/makeCardBadgeProps'

const DealCardUI = React.forwardRef<HTMLDivElement, DealsCardUI>(
  ({ decrementSku, incrementSku, quantity, deal }, ref) => {
    const ariaLabel = React.useMemo(() => shortId(), [])

    const badgeProps = isValidBadge(deal.badge)
      ? makeCardBadgeProps({ type: deal.badge }, deal)
      : null

    const cardOverlay = () => (
      <>
        <IconCheck customColor={{ base: 'white' }} size="small" />
        <Text color="white" component="span" variant="H4Condensed">
          In Your Box
        </Text>
      </>
    )

    return (
      <Card
        aria-describedby={ariaLabel}
        data-cy-current-quantity={quantity}
        data-cy-max-quantity={deal.maxQuantity}
        key={deal.sku}
        ref={ref}
      >
        {badgeProps && <CardBadge {...badgeProps} />}

        <Box
          display={{ mobile: 'block', desktop: 'flex' }}
          flexBasis={0}
          flexGrow={1}
        >
          <CardMedia
            alt={deal.altDescription}
            flexGrow={1}
            height={212}
            objectFit="cover"
            overlay={quantity > 0 ? cardOverlay : undefined}
            src={deal.image}
          >
            {quantity > 0 && <CardQuantity value={`${quantity}`} />}
          </CardMedia>
        </Box>

        <CardBody textAlign="left">
          <Text component="h3" id={ariaLabel} variant="H2Bold">
            {deal.description}
          </Text>

          <CardPrice price={formatPriceFromCents(deal.price)} />

          <Text variant="Body1Regular">
            <span dangerouslySetInnerHTML={{ __html: deal.fullDescription }} />
          </Text>
        </CardBody>

        <CardAction>
          <CardQuantityInput
            handleAddItem={incrementSku}
            handleRemoveItem={decrementSku}
            maxQuantity={deal.maxQuantity}
            product={deal}
            quantity={quantity}
            size="lg"
            variant="checkoutDeal"
          />
        </CardAction>
      </Card>
    )
  }
)

export default DealCardUI
