import { rem } from 'design'
import {
  BodySmaller,
  LinkExternal,
} from 'design/components/Typography/Typography'
import React from 'react'

/**
 * Turn Disclaimer into functional component
 * https://nbox-341.atlassian.net/browse/WEB-2656
 */
export const disclaimer = (
  <BodySmaller pr={{ desktop: rem(30) }}>
    Auto-renewal &amp; Cancellation Terms: You agree you are purchasing a
    continuous subscription and will receive regular deliveries on your selected
    schedule, billed to your designated payment method until you cancel. Pricing
    is based on your plan choices. The plan prices are available in your account
    page and are subject to change. You may cancel or pause your subscription at
    any time by visiting your account page or by contacting customer support via
    email at{' '}
    <LinkExternal href="mailto:support@butcherbox.com" target="_self">
      support@butcherbox.com
    </LinkExternal>
    . Any orders that have been processed, as reflected on your account page,
    cannot be cancelled. For more information see our{' '}
    <LinkExternal href="/terms-of-use">Terms of Use</LinkExternal> and{' '}
    <LinkExternal href="https://support.butcherbox.com/hc/en-us">
      FAQs
    </LinkExternal>
    .
  </BodySmaller>
)
