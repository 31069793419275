import { Lockup, rem, Text } from '@butcherbox/freezer'
import { styled } from 'design'
import { LargeButtonPrimary } from 'design/components/Button/Button'
import { Form } from 'formik'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import * as Styles from '~/routes/CheckoutFlow/CuratedMeatTypeSelectionFrame/CuratedMeatTypeSelection.css'
import { MeatGroupCard } from '~/routes/CheckoutFlow/MeatGroupCard/MeatGroupCard'
import { NextFrameButtonContainer } from '~/routes/CheckoutFlow/shared'
import { CuratedMeatTypeSelectionFrameUIProps } from '~/routes/CheckoutFlow/types'

export const CuratedMeatTypeSelectionFrameUI = ({
  isSubmitting,
  curatedMeatTypes,
  buttonText,
}: CuratedMeatTypeSelectionFrameUIProps) => {
  return (
    <div
      className={Styles.CuratedMeatTypeSelectionFrame}
      data-cy={TEST_ID.CHECKOUT_FLOW_CURATED_MEAT}
    >
      <Lockup className={Styles.Lockup}>
        <Text component="h1" variant="BodyH2Bold">
          Select your types of meat
        </Text>
        <Text component="div" variant="Body1Regular">
          Choose from 100% grass-fed, grass finished beef, free-range organic
          chicken, and pork raised crate-free.
        </Text>
      </Lockup>

      <Form>
        <fieldset
          aria-label="Available Curated Boxes"
          className={Styles.CuratedMeatTypeOptions}
        >
          {curatedMeatTypes.map(({ meatGroup, title }) => (
            <CuratedMeatTypeFrameStyled key={meatGroup}>
              <MeatGroupCard meatGroup={meatGroup} title={title} />
            </CuratedMeatTypeFrameStyled>
          ))}
        </fieldset>

        <NextFrameButtonContainer>
          <LargeButtonPrimary
            data-cy={TEST_ID.CHECKOUT_FLOW_NEXT_FRAME}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            type="submit"
          >
            {buttonText}
          </LargeButtonPrimary>
        </NextFrameButtonContainer>
      </Form>
    </div>
  )
}

const CuratedMeatTypeFrameStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

 @media(min-width: ${rem(768)}){
  &:nth-child(3):last-child {
    grid-column: span 2;
  },
 }
`
