import React from 'react'
import { CuratedMeatGroup } from '~/routes/CheckoutFlow/types'
import boxVariants from '~/utils/boxVariants'
import { MeatGroupCardUI } from './MeatGroupCard.ui'

export interface MeatGroupCardProps {
  meatGroup: CuratedMeatGroup
  title: string
}

export const MeatGroupCard = ({ meatGroup, title }: MeatGroupCardProps) => {
  const { render: getMeatGroupIcon } = boxVariants[meatGroup]

  return (
    <MeatGroupCardUI
      icon={getMeatGroupIcon()}
      meatGroup={meatGroup}
      title={title}
    />
  )
}
