import { ShowToast } from 'design/contexts/Toast/Toast.context'
import React from 'react'
import { useMutation } from 'react-query'
import { UPDATE_CHECKOUT_SUMMARY } from '~/bb-api/endpoints'
import {
  APIException,
  CheckoutSettings,
  CheckoutSummary,
  GroupedBoxItem,
  NewSubscription,
} from '~/bb-api/schemata'
import { PaymentState } from '~/routes/CheckoutFlow/PaymentFrame/types'
import { containsErrorFromAPI } from '~/utils/apiErrors'
import { stripInternalFormData } from '~/utils/form'

export function useCheckoutSummary(
  state: PaymentState,
  checkoutSettings: CheckoutSettings,
  prevDiscountCode: React.MutableRefObject<string>,
  handleUpdateDiscountCode: (code: string) => void,
  fireToast: ShowToast
) {
  const [fetchCount, setFetchCount] = React.useState(0)
  const mutation = useMutation<
    CheckoutSummary,
    Response,
    {
      couponCode: string | null
      extras: Array<Pick<GroupedBoxItem, 'sku' | 'quantity'>>
      invoiceItems: Array<GroupedBoxItem>
      state: PaymentState
      subscription: NewSubscription
      isShopifyCheckoutEnabled: boolean
    }
  >(
    ({
      couponCode: providedCouponCode,
      extras,
      invoiceItems,
      state: providedState,
      subscription: providedSubscription,
      isShopifyCheckoutEnabled,
    }) =>
      fetch(UPDATE_CHECKOUT_SUMMARY, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          couponCode: providedCouponCode,
          customer: {
            email: providedState.email,
            shippingAddress: stripInternalFormData(providedState.shipping),
          },
          extras: extras || [],
          invoiceItems: invoiceItems || [],
          subscription: providedSubscription,
          shouldEnableShopify: isShopifyCheckoutEnabled,
        }),
      }).then((res) => {
        if (res.status === 200) {
          return res.json()
        } else {
          return Promise.reject(res)
        }
      }),
    {
      onSettled: () => {
        setFetchCount(fetchCount + 1)
      },
      onSuccess: (body) => {
        const couponCode =
          state.discountCode || checkoutSettings?.discountCode || null

        if (couponCode && couponCode !== prevDiscountCode.current) {
          prevDiscountCode.current = couponCode

          if (body.discountAmount === 0) {
            handleUpdateDiscountCode(null) // clear it out

            fireToast('error', {
              children: `The discount code "${
                state.discountCode || checkoutSettings?.discountCode
              }" is not valid`,
            })
          } else {
            fireToast('success', {
              children: `Your coupon has been applied!`,
            })
          }
        }
      },
      onError: async (res, variables) => {
        const body = (await (res.json() as unknown)) as APIException
        if (res.status === 400) {
          if (containsErrorFromAPI(body, 'subscription.interval')) {
            fireToast('error', {
              children: 'Please select a billing frequency on step 2.',
            })
          }
          if (containsErrorFromAPI(body, 'invalid couponcode')) {
            handleUpdateDiscountCode(null) // clear it out

            fireToast('error', {
              children: 'Discount code invalid. Please try a different code.',
            })
          }
        } else {
          // Generic error for when a user attempted to apply a coupon code
          // but we don't know why the request failed.
          if (variables.couponCode !== prevDiscountCode.current) {
            fireToast('error', {
              children:
                'There was a problem applying your discount code. Please try again or contact customer service for help.',
            })
          }
        }
      },
    }
  )

  return [...mutation, fetchCount] as const
}
