import { useQuery } from 'react-query'
import { CACHE_KEY_BOX_ITEMS } from '~/bb-api/constants'
import { GET_BOX_ITEMS } from '~/bb-api/endpoints'
import { AvailableCustomCuts } from '~/bb-api/schemata'
import axios from '~/utils/axios'

export default function useBoxItems() {
  return useQuery(CACHE_KEY_BOX_ITEMS, () =>
    axios.get<AvailableCustomCuts>(GET_BOX_ITEMS).then((res) => res.data)
  )
}
