import { rem } from '@butcherbox/freezer'
import { Box } from '@chakra-ui/core'
import { OverlayContainer } from '@react-aria/overlays'
import { useOverlayTriggerState } from '@react-stately/overlays'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import Cookies from 'js-cookie'
import React from 'react'
import { trackEmailOptinModalSeen } from '~/analytics/events'
import { ModalEmailOptin } from '~/components/ModalEmailOptin/ModalEmailOptin'
import { ModalEmailOptinTrigger } from '~/components/ModalEmailOptin/ModalEmailOptinTrigger'
import useExitIntent from '~/hooks/useExitIntent'
import useOptimizelyExperiment from '~/hooks/useOptimizelyExperiment'
import { useSessionStorage } from '~/hooks/useStorage'
import { EMAIL_SUBMITTED } from '~/routes/constants'
import { JOIN_586_VARIANTS } from '~/types/join-586-bypass-get-started'
import {
  JOIN_554_VARIANTS,
  JOIN_556_VARIANTS,
  JOIN_571_VARIANTS,
} from '../JOIN-440-462-email-skip/EmailSkipExperiment.types'

export const EmailOptinModalContext = React.createContext(null)

export function EmailOptinModalProvider({ children }) {
  const [isEmailSubmittedInSession] = useSessionStorage(EMAIL_SUBMITTED, null)
  const landerURL = Cookies.get('bbmc_lander')
  // checks if lander url ended in -u to determine if coming from ungated lander
  let isFromUngatedLander = false
  let isFromGatedLander = false
  let isFromGetStarted = false

  if (landerURL) {
    isFromUngatedLander = landerURL?.slice(-2) === '-u'
    isFromGatedLander = landerURL?.slice(-2) === '-g'
    isFromGetStarted = landerURL === 'get-started'
  }

  const retestSkipEmailGateExperimentKey = isFromGetStarted
    ? 'join-556__retest_skip_email_gate'
    : ''
  const [emailModalExperimentVariant] = useOptimizelyExperiment(
    retestSkipEmailGateExperimentKey
  ) as [JOIN_556_VARIANTS, null, null]

  const ungatedEmailExperimentKey = isFromUngatedLander
    ? 'join-571__ungated_save_email_option'
    : ''
  const [showModalLinkAfterUngatedVariant] = useOptimizelyExperiment(
    ungatedEmailExperimentKey
  ) as [JOIN_571_VARIANTS, null, null]

  // Passes in null to prevent data collection / bucketing if from ungated lander
  const shouldRun554Experiment = isFromGatedLander
    ? 'JOIN-554__email-capture-contentful'
    : ''
  const [emailSubmissionVariant] = useOptimizelyExperiment(
    shouldRun554Experiment
  ) as [JOIN_554_VARIANTS, null, null]

  // Experiment is for unauth users to bypass /get-started; landers are basically /get-started
  // Additionally removes conflicts with other experiments in shouldShowCta hook below
  const shouldRun586Experiment =
    isFromGatedLander || isFromUngatedLander
      ? null
      : 'join-586__bypassing-get-started'
  const [shouldBypassGetStarted] = useOptimizelyExperiment(
    shouldRun586Experiment
  ) as [JOIN_586_VARIANTS, null, null]

  /*
    Experiment bucketing conflicts cause CTA button could possibly be needed for
    one user group but NOT another. Example: if bucketed in 571 'save_email' and
    586's 'control' group (or vice versa).
  */
  const [shouldShowCta, setShouldShowCTA] = React.useState(
    () =>
      (!isEmailSubmittedInSession &&
        emailModalExperimentVariant === JOIN_556_VARIANTS?.SKIP_EMAIL &&
        !isFromUngatedLander) ||
      (!isEmailSubmittedInSession &&
        emailSubmissionVariant === JOIN_554_VARIANTS?.SKIP_EMAIL) ||
      (!isEmailSubmittedInSession &&
        shouldBypassGetStarted === JOIN_586_VARIANTS?.BYPASS_GET_STARTED) ||
      (!isEmailSubmittedInSession &&
        showModalLinkAfterUngatedVariant === JOIN_571_VARIANTS?.SAVE_EMAIL &&
        isFromUngatedLander)
  )

  const state = useOverlayTriggerState({})
  const [isSubmitted, setIsSubmitted] = React.useState(
    Boolean(isEmailSubmittedInSession)
  )
  const [modalSeen, setModalSeen] = React.useState(false)
  const fireToast = React.useContext(ToastContext)

  const shouldEnableExitItent = React.useMemo(() => {
    return (
      !modalSeen && //if they haven't seen the modal
      ![
        '/plans-and-addons',
        '/plans-and-addons/checkout',
        '/plans-and-addons/type',
      ].some((x) => location.pathname === x) && //if it's not checkout or plans and addons
      !isSubmitted && //they have not already submitted their email via cta
      ((emailSubmissionVariant && //if they are in the experiment
        emailSubmissionVariant === JOIN_554_VARIANTS?.SKIP_EMAIL) ||
        (emailModalExperimentVariant &&
          emailModalExperimentVariant === JOIN_556_VARIANTS?.SKIP_EMAIL) ||
        (shouldBypassGetStarted &&
          shouldBypassGetStarted === JOIN_586_VARIANTS?.BYPASS_GET_STARTED) ||
        (showModalLinkAfterUngatedVariant &&
          showModalLinkAfterUngatedVariant === JOIN_571_VARIANTS?.SAVE_EMAIL))
    )
  }, [
    modalSeen,
    isSubmitted,
    emailSubmissionVariant,
    emailModalExperimentVariant,
    shouldBypassGetStarted,
    showModalLinkAfterUngatedVariant,
  ])

  const [exitIntentDetected, resetExitIntent] = useExitIntent({
    enabled: shouldEnableExitItent,
    triggerOnPageBlur: true,
  })

  React.useEffect(() => {
    if (exitIntentDetected) {
      state.open()
      setModalSeen(true)
    }
  }, [exitIntentDetected, resetExitIntent, state])

  const handleClose = (hasSubmitted?: boolean) => {
    trackEmailOptinModalSeen(
      location.pathname,
      exitIntentDetected ? 'exit intent' : 'cta click'
    )

    state.close()

    if (hasSubmitted === true) {
      setShouldShowCTA(false)
      setIsSubmitted(true)
      setModalSeen(true)
      fireToast('success', {
        children: 'Congratulations, your special offer has been saved!',
      })
    }

    resetExitIntent()
  }

  return (
    <EmailOptinModalContext.Provider
      value={{
        state,
        handleClose,
        shouldShowCta,
        ctaOrExitIntent: exitIntentDetected ? 'exit intent' : 'cta click',
      }}
    >
      {children}
      {!location.pathname.includes('checkout') && (
        // this css is kinda a bandaid for the test to prevent the footer from looking funny and  a white bar appearing after cta disappears
        <Box
          marginTop={shouldShowCta ? rem(-86) : rem(-66)}
          paddingBottom={rem(56)}
        >
          {shouldShowCta && <ModalEmailOptinTrigger />}
          {state.isOpen ? (
            <OverlayContainer>
              <ModalEmailOptin closeFunction={handleClose} />
            </OverlayContainer>
          ) : null}
        </Box>
      )}
    </EmailOptinModalContext.Provider>
  )
}
