import { Box, Stack, VisuallyHidden } from '@chakra-ui/core'
import { OptimizelyContext } from '@optimizely/react-sdk'
import { AxiosResponse } from 'axios'
import { rem } from 'design'
import { LargeButtonPrimary } from 'design/components/Button/Button'
import ButtonToast from 'design/components/Button/ButtonToast'
import Checkbox from 'design/components/Checkbox/Checkbox'
import IconSatisfactionGuarantee from 'design/components/Icons/defs/SatisfactionGuarantee'
import {
  Body,
  BodySmaller,
  CTA,
  H2,
  LinkExternal,
} from 'design/components/Typography/Typography'
import { LayoutContext } from 'design/contexts/layout'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import { navigate } from 'gatsby'
import Cookies from 'js-cookie'
import React from 'react'
import { useMutation } from 'react-query'
import { trackError } from '~/analytics/errors'
import {
  dataDogCheckoutFunnel,
  trackAccountExistsError,
  trackCheckoutStepCompleted,
  trackDataDogOrderEvent,
  waitFor,
} from '~/analytics/events'
import { segmentIdentify } from '~/analytics/identifiers'
import { CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS } from '~/bb-api/constants'
import { CREATE_SUBSCRIPTION } from '~/bb-api/endpoints'
import {
  APIException,
  BoxSize,
  CheckoutSuccess,
  CheckoutSummary,
  CreateSubscription,
  NewSubscription,
} from '~/bb-api/schemata'
import CollapseComponent from '~/components/Collapse'
import { TEST_ID } from '~/constants/cypress'
import { CheckoutStateContext } from '~/context/checkoutState'
import { StagedSubscriptionContext } from '~/context/stagedSubscription'
import useCheckoutSettings from '~/hooks/useCheckoutSettings'
import {
  COOKIE_OFFER_IDENTIFIER,
  Frames,
  ID_TOS_CHECKBOX,
} from '~/routes/CheckoutFlow/constants'
import { Header } from '~/routes/CheckoutFlow/PaymentFrame/Header'
import { PaymentContext } from '~/routes/CheckoutFlow/PaymentFrame/PaymentContext'
import {
  ICongratulationsState,
  IOrderSummary,
  PaymentState,
} from '~/routes/CheckoutFlow/PaymentFrame/types'
import { CheckoutLinkInternal } from '~/routes/CheckoutFlow/shared'
import { formatPriceFromCents, formatPriceWithoutTrailingZeroes } from '~/utils'
import { getAPIErrors, getFirstErrorFromAPI } from '~/utils/apiErrors'
import axios from '~/utils/axios'
import { normalizeBoxName } from '~/utils/boxVariants'
import { stripInternalFormData } from '~/utils/form'
import { humanizeBoxSize } from '~/utils/humanize'

export const OrderSummary = React.memo(
  ({
    boxPrice,
    calculatedSubtotal,
    setIsOrderSubmitting,
    setShouldRefreshStripeToken,
    summary,
    ...props
  }: IOrderSummary) => {
    const state = React.useContext(PaymentContext)
    const { stagedSubscription } = React.useContext(StagedSubscriptionContext)
    const subscription = stagedSubscription as Required<NewSubscription>
    const fireToast = React.useContext(ToastContext)
    const [open, setOpen] = React.useState(false)
    const [acceptedTOS, setAcceptedTOS] = React.useState(false)
    const { isMobile } = React.useContext(LayoutContext)

    const { data: settings } = useCheckoutSettings([
      CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS,
      {
        offerId: Cookies.get(COOKIE_OFFER_IDENTIFIER),
      },
    ])

    const checkoutId = settings?.checkoutId
    const { invoiceItems } = React.useContext(CheckoutStateContext)

    const couponCode = state.discountCode || settings?.discountCode || null
    const { optimizely } = React.useContext(OptimizelyContext)

    const [finalizePurchase, { isLoading }] = useMutation<
      AxiosResponse<CheckoutSuccess>,
      AxiosResponse<APIException>,
      {
        acceptedTOS: boolean
        couponCode: string | null
        state: PaymentState
        subscription: NewSubscription
        summary: CheckoutSummary
      }
    >(
      ({
        acceptedTOS: providedAcceptedTOS,
        couponCode: providedCouponCode,
        subscription: providedSubscription,
        state: providedState,
        summary: providedSummary,
      }) =>
        axios.post(
          CREATE_SUBSCRIPTION,
          {
            agreeToTerms: providedAcceptedTOS,
            couponCode: providedCouponCode,
            customer: {
              email: providedState.email,
              phoneNumber: providedState.phoneNumber,
              billingInformation: {
                ...providedState.billing,
                phoneNumber: providedState.phoneNumber,
                stripeToken: providedState.billing?.stripeToken,
              },
              reference: null,
              shippingAddress: stripInternalFormData(providedState.shipping),
            },
            extras: providedSummary?.extras || [],
            shipmentWeek: providedState.shipping?.shipmentWeek || null,
            subscription: providedSubscription,
            invoiceItems: providedSummary?.invoiceItems || [],
          } as CreateSubscription,
          { withCredentials: true }
        ),
      {
        onSuccess: async (
          response,
          { state: providedState, summary: providedSummary }
        ) => {
          const customer = response.data.customer
          try {
            optimizely?.track(
              'first_order_checkout_success', //key
              optimizely.user.id, //userid
              optimizely.user.attributes, //user attribute
              {
                revenue: summary.total, // tags, revenue has to be recorded in cents
              }
            )

            trackDataDogOrderEvent(dataDogCheckoutFunnel.SUCCESS)

            segmentIdentify({
              userId: customer?.id.toString(),
              traits: {
                email: customer?.emailAddress,
                distribution_center: customer?.shipZone?.preferredFacility,
                first_name: customer?.firstName,
                last_name: customer?.lastName,
              },
            })

            if (checkoutId) {
              await waitFor(trackCheckoutStepCompleted, { timeout: 5000 })({
                checkout_id: checkoutId,
                step: 4,
              })
            }
          } catch (e) {
            trackError((scope) => {
              scope.capture(e)
            })
          }

          const congratsState = {
            ...providedState,
            ...providedSummary,
            ...response.data,
          }
          /*
            Manually adding our offers property for congratulations state data; when
            spreading in the three objects ^ above to a single object, "offers" array
            was being overwritten; resulted in the offer missing from an "Order Summary"
          */
          congratsState.subscription.box.offers =
            providedSummary.subscription.box.offers

          navigate('/congratulations', {
            state: congratsState as ICongratulationsState,
          })
        },
        onError: (e) => {
          setShouldRefreshStripeToken(true)

          console.log('e?.data?.errors', e?.data?.errors)
          const error = getFirstErrorFromAPI(e?.data)

          setIsOrderSubmitting(false)

          fireToast('error', {
            children: error,
            duration: 10000,
          })
          if (
            (e?.data?.errors && e.data.errors[`customer.email`]) ||
            (e?.data?.errors &&
              e.data.errors[`customer.shippingAddress.deliveryInstructions`])
          ) {
            trackDataDogOrderEvent(dataDogCheckoutFunnel.USER_ERROR, error)
          } else {
            trackDataDogOrderEvent(dataDogCheckoutFunnel.FAILED, error)
          }

          if (e?.data?.errors && e?.data?.errors[`customer.email`]) {
            trackAccountExistsError(e?.data?.errors[`customer.email`][0])
          }

          if (e?.status && e?.statusText) {
            trackError((scope) => {
              if (e?.data?.errors) {
                scope.setContext('errors', {
                  errors: getAPIErrors(e.data),
                })
              }

              if (e?.data?.message) {
                scope.setContext('errors', {
                  message: e.data.message,
                })
              }

              scope.setContext('API response', e)
              scope.capture(
                new Error(
                  `${e.status} - ${e.statusText} returned from Create Subscription API`
                )
              )
            })
          }
        },
      }
    )

    const [error, setError] = React.useState<string | null>(null)
    const handleRef = React.useRef<number | undefined | null>(undefined)

    const triggerErrorToast = React.useCallback((errorMessage: string) => {
      setError(errorMessage)

      if (handleRef.current) window.clearTimeout(handleRef.current)

      handleRef.current = window.setTimeout(() => {
        handleRef.current = null
        setError(null)
      }, 5000)
    }, [])

    const handleClickPurchase = React.useCallback(() => {
      setIsOrderSubmitting(true)
      if (isLoading) return

      if (!state.shipping) {
        setIsOrderSubmitting(false)
        trackDataDogOrderEvent(
          dataDogCheckoutFunnel.USER_ERROR,
          'Please finish filling out the shipping info form'
        )
        return triggerErrorToast(
          'Please finish filling out the shipping info form'
        )
      } else if (!state.billing) {
        setIsOrderSubmitting(false)
        trackDataDogOrderEvent(
          dataDogCheckoutFunnel.USER_ERROR,
          'Please finish filling out the billing info form'
        )
        return triggerErrorToast(
          'Please finish filling out the billing info form'
        )
      } else if (!acceptedTOS) {
        setIsOrderSubmitting(false)
        trackDataDogOrderEvent(
          dataDogCheckoutFunnel.USER_ERROR,
          'You must accept the terms of use to sign up with ButcherBox'
        )
        return triggerErrorToast(
          'You must accept the terms of use to sign up with ButcherBox'
        )
      }

      if (summary)
        finalizePurchase({
          acceptedTOS,
          couponCode,
          subscription,
          state,
          summary,
        })
    }, [
      acceptedTOS,
      couponCode,
      finalizePurchase,
      isLoading,
      setIsOrderSubmitting,
      state,
      subscription,
      summary,
      triggerErrorToast,
    ])

    const submitButtonReady = acceptedTOS && !!state.billing && !!state.shipping
    const total =
      summary?.total !== undefined && summary?.total >= 0
        ? formatPriceFromCents(summary?.total)
        : calculatedSubtotal
        ? formatPriceFromCents(calculatedSubtotal)
        : null

    const text = (
      <Body
        color="bb.slate"
        d="flex"
        justifyContent="space-between"
        order={2}
        w="100%"
      >
        <span>
          {subscription?.box.type === 'basic_beef_chicken_pork'
            ? 'Basic Box'
            : `${normalizeBoxName(subscription?.box)} ${humanizeBoxSize(
                subscription?.box.size as BoxSize
              )}`}
        </span>

        {summary ? (
          <span>{formatPriceFromCents(summary.subscriptionPrice)}</span>
        ) : boxPrice > 0 ? (
          formatPriceFromCents(boxPrice)
        ) : null}
      </Body>
    )

    return (
      <Box alignSelf="flex-start">
        <Box
          border="1px solid"
          borderColor="bb.granite"
          data-where="order-summary"
          {...props}
        >
          <Header bg="bb.slate" color="white" pos="relative" px={rem(24)}>
            <H2>Order Summary</H2>
            <CheckoutLinkInternal
              aria-label="Edit Box Type"
              color="white"
              href={Frames.BoxTypeSelectionFrame}
              right={rem(24)}
              top={rem(11)}
            >
              <CTA>Edit</CTA>
            </CheckoutLinkInternal>
          </Header>

          <Box p={rem(24)}>
            {subscription.box.type === 'cst' ? (
              <>
                <CollapseComponent
                  buttonStyleProps={{
                    display: 'flex',
                    style: { width: '100%' },
                    'data-cy': undefined,
                  }}
                  collapsedText={text}
                  expandedText={text}
                  iconStyleProps={{
                    marginRight: 4,
                    marginTop: 4,
                    marginLeft: 0,
                    display: 'block',
                  }}
                  isOpen={open}
                  onClick={() => setOpen(!open)}
                >
                  <Box bg="bb.silt" mt={rem(8)} p={rem(8)}>
                    {subscription.box.items.map((item) => (
                      <Body
                        d="flex"
                        justifyContent="space-between"
                        key={item.sku}
                      >
                        <span>{item.description}</span>
                        <Box pl={rem(10)} textAlign="right">
                          QTY: {item.quantity}
                        </Box>
                      </Body>
                    ))}
                  </Box>
                </CollapseComponent>
                <Box
                  borderBottom="2px solid"
                  borderBottomColor="bb.granite"
                  d={open ? 'none' : 'block'}
                  mt={rem(11)}
                />
              </>
            ) : (
              text
            )}

            {settings?.offer ? (
              <Body
                bg="bb.silt"
                d="flex"
                justifyContent="space-between"
                mx={rem(-24)}
                my={rem(20)}
                px={rem(24)}
                py={rem(5)}
              >
                <span>
                  {settings.offer.description}
                  <br />
                  QTY: 1
                </span>
                <Body alignSelf="flex-end" pl={rem(10)} textAlign="right">
                  FREE
                </Body>
              </Body>
            ) : null}

            {subscription.box.addons.length ? (
              <>
                <Body
                  mt={rem(15)}
                  textDecoration="underline"
                  textTransform="uppercase"
                >
                  Add-ons
                </Body>

                <Stack spacing={rem(18)}>
                  {subscription.box.addons.map((addon) => (
                    <Body
                      d="flex"
                      justifyContent="space-between"
                      key={addon.sku}
                    >
                      <span>
                        {addon.description}
                        <br />
                        QTY: {addon.quantity}
                      </span>
                      <Body alignSelf="flex-end" pl={rem(10)} textAlign="right">
                        {formatPriceWithoutTrailingZeroes(
                          addon.price * addon.quantity
                        )}
                      </Body>
                    </Body>
                  ))}
                </Stack>
              </>
            ) : null}

            {summary?.extras.length ? (
              <>
                <Body
                  mt={rem(15)}
                  textDecoration="underline"
                  textTransform="uppercase"
                >
                  Extras
                </Body>

                <Stack spacing={rem(18)}>
                  {summary.extras.map((extraProduct) => (
                    <Body
                      d="flex"
                      justifyContent="space-between"
                      key={extraProduct.sku}
                    >
                      <span>
                        {extraProduct.description}
                        <br />
                        QTY: {extraProduct.quantity}
                      </span>
                      <Box
                        alignSelf="flex-end"
                        as="span"
                        pl={rem(10)}
                        textAlign="right"
                      >
                        FREE
                      </Box>
                    </Body>
                  ))}
                </Stack>
              </>
            ) : null}

            {invoiceItems.length ? (
              <>
                <Body
                  mt={rem(15)}
                  textDecoration="underline"
                  textTransform="uppercase"
                >
                  Member Deals
                </Body>

                <Stack spacing={rem(18)}>
                  {invoiceItems.map((invoiceItem) => (
                    <Body
                      d="flex"
                      justifyContent="space-between"
                      key={invoiceItem.sku}
                    >
                      <span>
                        {invoiceItem.description}
                        <br />
                        QTY: {invoiceItem.quantity}
                      </span>
                      <Body alignSelf="flex-end" pl={rem(10)} textAlign="right">
                        {formatPriceWithoutTrailingZeroes(
                          invoiceItem.price * invoiceItem.quantity
                        )}
                      </Body>
                    </Body>
                  ))}
                </Stack>
              </>
            ) : null}

            <Box mt={rem(30)}>
              <table width={'100%'}>
                <caption>
                  <VisuallyHidden>Order Summary</VisuallyHidden>
                </caption>
                <tbody>
                  <Body
                    as="tr"
                    bg="bb.silt"
                    d="flex"
                    justifyContent="space-between"
                    px={rem(8)}
                    py={rem(2)}
                  >
                    <th scope="row" style={{ fontWeight: 'normal' }}>
                      Subtotal
                    </th>
                    <Box as="td" pl={rem(10)} textAlign="right">
                      {summary?.subtotal || calculatedSubtotal
                        ? formatPriceFromCents(
                            summary?.subtotal || calculatedSubtotal
                          )
                        : null}
                    </Box>
                  </Body>
                  <Body
                    as="tr"
                    d="flex"
                    justifyContent="space-between"
                    px={rem(8)}
                    py={rem(2)}
                  >
                    <th scope="row" style={{ fontWeight: 'normal' }}>
                      Delivery
                    </th>
                    <Box as="td" pl={rem(10)} textAlign="right">
                      FREE
                    </Box>
                  </Body>

                  <Body
                    as="tr"
                    d="flex"
                    justifyContent="space-between"
                    px={rem(8)}
                    py={rem(2)}
                  >
                    <th scope="row" style={{ fontWeight: 'normal' }}>
                      Estimated Tax
                    </th>
                    <Box as="td" pl={rem(10)} textAlign="right">
                      {formatPriceFromCents(summary?.taxAmount || 0)}
                    </Box>
                  </Body>

                  {summary?.discountAmount && summary.discountAmount !== 0 ? (
                    <Body
                      as="tr"
                      d="flex"
                      justifyContent="space-between"
                      px={rem(8)}
                      py={rem(2)}
                    >
                      <th scope="row" style={{ fontWeight: 'normal' }}>
                        Discount
                      </th>
                      <Box as="td" pl={rem(10)} textAlign="right">
                        {formatPriceFromCents(summary.discountAmount)}
                      </Box>
                    </Body>
                  ) : null}

                  <Body
                    as="tr"
                    bg="bb.silt"
                    d="flex"
                    justifyContent="space-between"
                    px={rem(8)}
                    py={rem(2)}
                  >
                    <th scope="row" style={{ fontWeight: 'normal' }}>
                      Total
                    </th>
                    <Box as="td" pl={rem(10)} textAlign="right">
                      {total}
                    </Box>
                  </Body>
                </tbody>
              </table>
            </Box>
            <Checkbox
              data-cy={TEST_ID.CHECKOUT_FLOW_TERMS_AND_CONDITIONS_CHECKBOX}
              // @ts-expect-error not implementing onFocus/onBlur
              field={{
                onChange: (e) => setAcceptedTOS(e.target.checked),
                value: acceptedTOS,
              }}
              id={ID_TOS_CHECKBOX}
              label={
                <>
                  I agree to the auto-renewal and cancellation terms as well as
                  the{' '}
                  <LinkExternal href="/terms-of-use" target="_blank">
                    Terms of Use
                  </LinkExternal>
                </>
              }
              mt={rem(12)}
            />

            <Box
              maxW={{ tablet: rem(300) }}
              mb={rem(12)}
              mt={rem(60)}
              mx="auto"
              pos="relative"
            >
              {error && <ButtonToast alignItems="center" error={error} />}
              <LargeButtonPrimary
                aria-busy={!submitButtonReady}
                aria-label={`Complete purchase of the ButcherBox ${normalizeBoxName(
                  subscription.box
                )} box plan${
                  subscription.box.addons.length
                    ? `, with add-ons: ${subscription.box.addons
                        .map((x) => x.description)
                        .join(', ')}`
                    : ''
                }${
                  summary?.extras.length
                    ? `, with free extra products: ${summary?.extras
                        .map((x) => x.description)
                        .join(', ')}`
                    : ''
                } for ${total}`}
                data-cy={TEST_ID.CHECKOUT_FLOW_PAYMENT_SUBMIT}
                isDisabled={isMobile && !submitButtonReady}
                isLoading={isLoading}
                onClick={handleClickPurchase}
                w="100%"
              >
                Purchase
              </LargeButtonPrimary>
            </Box>

            <BodySmaller
              textAlign={{ base: 'left', tablet: 'center', desktop: 'left' }}
            >
              <strong>
                <Box
                  as="span"
                  color="bb.spicedCrimson"
                  textTransform="uppercase"
                >
                  Pause or cancel anytime -{' '}
                </Box>
                We think you'll love ButcherBox - your satisfaction is 100%
                guaranteed. You can pause or cancel{' '}
                <Box as="span" color="bb.spicedCrimson">
                  anytime
                </Box>
                .
              </strong>
            </BodySmaller>
          </Box>
        </Box>

        <Stack
          align="center"
          direction="row"
          justify="center"
          mt={rem(44)}
          spacing={rem(20)}
        >
          <LinkExternal href="https://www.bbb.org/us/ma/boston/profile/food-and-beverage-services/butcherbox-llc-0021-186401/accreditation-information">
            <img
              alt="Better Business Bureau accredited business"
              src="https://www.bbb.org/TerminusContent/dist/img/business-profile/accreditation/AB-seal-horz.svg"
              style={{ height: 60 }}
            />
          </LinkExternal>
          <IconSatisfactionGuarantee height={rem(140)} width={112} />
        </Stack>
      </Box>
    )
  }
)
