import React from 'react'

type IExitIntentOptions = {
  enabled?: boolean
  triggerOnPageBlur?: boolean
}

/**
 *
 * This hook returns a boolean indicating whether exit intent has been detected.
 * It does this by tracking the user's mouse position. It can also optionally
 * track whether the page has lost focus as well using the triggerOnPageBlur
 * boolean.
 *
 */
function useExitIntent(
  { enabled, triggerOnPageBlur }: IExitIntentOptions = {
    enabled: true,
    triggerOnPageBlur: true,
  }
): [boolean, () => void] {
  const [exitIntentDetected, setExitIntentDetected] = React.useState(false)

  const checkPageVisible = React.useCallback(() => {
    if (window.Cypress || !triggerOnPageBlur) return

    if (document.visibilityState == 'hidden') {
      setExitIntentDetected(true)
    }
  }, [triggerOnPageBlur])

  const checkExitIntent = React.useCallback((e: MouseEvent) => {
    if (window.Cypress) return

    if (!e.relatedTarget && e.clientY < 10) {
      setExitIntentDetected(true)
    }
  }, [])

  const resetExitIntent = React.useCallback(() => {
    setExitIntentDetected(false)
  }, [])

  React.useEffect(() => {
    if (!enabled) return

    document.addEventListener('mousemove', checkExitIntent)
    document.addEventListener('visibilitychange', checkPageVisible)

    return () => {
      document.removeEventListener('mousemove', checkExitIntent)
      document.removeEventListener('visibilitychange', checkPageVisible)
    }
  }, [checkExitIntent, checkPageVisible, enabled, triggerOnPageBlur])

  return [exitIntentDetected, resetExitIntent]
}

export default useExitIntent
