export enum JOIN_554_VARIANTS {
  CONTROL = 'control',
  SKIP_EMAIL = 'skip_email',
}

export enum JOIN_556_VARIANTS {
  CONTROL = 'control',
  SKIP_EMAIL = 'skip_email',
}

export enum JOIN_571_VARIANTS {
  CONTROL = 'control',
  SAVE_EMAIL = 'save_email',
}
