import set from 'clean-set'
import { ShowToast, ToastContext } from 'design/contexts/Toast/Toast.context'
import React from 'react'
import {
  BoxTypeDefinition,
  CheckoutCartData,
  CheckoutSettings,
  NewSubscription,
} from '~/bb-api/schemata'
import { CheckoutStateContext } from '~/context/checkoutState'
import { StagedSubscriptionContext } from '~/context/stagedSubscription'
import {
  SESSION_STORAGE_CHECKOUT_ID_KEY,
  SESSION_STORAGE_IS_CART_RECLAIMER_KEY,
} from '~/routes/CheckoutFlow/constants'
import { CHECKOUT_FETCH_STATE } from './useCheckoutPromise'
import { useSessionStorage } from './useStorage'

//used in useEffect on line 172
const STATE_GRAPH = {
  loading: {
    //initial state when we are still completing the initial data fetches
    TOGGLE: 'success',
    BREAK: 'error',
  },
  success: {
    //triggers when we have completed loading in the cart data, checkout settings, and box types
    TOGGLE: 'setSubscription',
    BREAK: 'error',
  },
  setSubscription: {
    //triggers after success to start setting the current subscription based on cart data or default (handleInitialStageSubscription)
    TOGGLE: 'setToast',
    BREAK: 'error',
  },
  setToast: {
    // after subscription is set and we have all of the cart data, we can fire the appropriate toasts in setToast
    TOGGLE: 'done',
    BREAK: 'error',
  },
  done: {
    //naming is hard
    BREAK: 'error',
  },
  error: {},
}

export enum CHECKOUT_ACTION_STATE {
  SUCCESS = 'success',
  LOADING = 'loading',
  SET_SUB = 'setSubscription',
  SET_TOAST = 'setToast',
  DONE = 'done',
  ERROR = 'error',
}

export const checkoutStateReducer = (state, event) => {
  const nextState = STATE_GRAPH[state][event]
  return nextState !== undefined ? nextState : state
}

/**
 * progresses through a state machine based on the status of the fetches created in useCheckoutPromise;
 * sets staged subscription and toasts;
 * returns a string with the current status
 * @param {object} state
 * @param {string} checkoutStatus - current status of our promise fetching all of the data in useCheckoutPromise
 * @param {BoxTypeDefinition[]} boxTypes
 * @param {CheckoutSettings} settings
 * @param {CheckoutCartData} cartData
 * @param {object} params searchParams from URL if including a reclaim URLcart, coupon, exclusive_offer
 * @param {boolean} checkedCookies true if useCheckoutPromise has successfully processed new offer cookies
 */
export const useCheckoutStateActions = (
  state: {
    toast: Parameters<ShowToast>
  },
  checkoutStatus: string,
  boxTypes: BoxTypeDefinition[],
  settings: CheckoutSettings,
  cartData: CheckoutCartData,
  params: {
    cart?: string
    coupon?: string
    exclusive_offer?: string
  },
  checkedCookies: boolean
): string => {
  const [checkoutActionState, updateCheckoutActionState] = React.useReducer(
    checkoutStateReducer,
    checkoutStatus
  )

  const { stagedSubscription, updateStagedSubscription } = React.useContext(
    StagedSubscriptionContext
  )

  const [, setCheckoutIdInSession] = useSessionStorage(
    SESSION_STORAGE_CHECKOUT_ID_KEY,
    null
  )
  const [, setIsCartReclaimerInSession] = useSessionStorage(
    SESSION_STORAGE_IS_CART_RECLAIMER_KEY,
    null
  )

  const fireToast = React.useContext(ToastContext)

  const {
    setInvoiceItems,
    initialBoxType,
    setInitialBoxType,
  } = React.useContext(CheckoutStateContext)

  const handleInitialStageSubscription = React.useCallback(() => {
    const defaultBoxType = boxTypes.find((x) => x.isDefault) || {
      badge: 'Best Value',
      description:
        'Your Choice of 100% Grass-Fed, Grass-Finished Beef; Free-Range Organic Chicken; Humanely Raised Pork; and Wild-Caught Seafood.',
      isDefault: true,
      isDisabled: false,
      isVisible: true,
      name: 'Your Choice',
      type: 'cst',
    }
    const newStagedSubscription = set(stagedSubscription, 'box', {
      ...stagedSubscription.box,
      name: defaultBoxType.name,
      size: 'classic',
      type: defaultBoxType.type,
    })

    newStagedSubscription.interval = settings?.defaultFrequency || 'every_month'
    //if we have a saved cart with a subscription, merge that subscription data into the staged subscription
    if (cartData?.subscription.box.type) {
      updateStagedSubscription({
        ...cartData?.subscription,
      } as NewSubscription)
      setInvoiceItems(cartData?.invoiceItems || [])
    } else if (!stagedSubscription?.box?.type) {
      // If there's no data in the saved cart, set the default
      updateStagedSubscription(newStagedSubscription)
    }
    //if searchParams contain 'cart' key in the url, update our session storage with the cart id and reclaimer status
    if (params.cart) {
      setCheckoutIdInSession(params.cart)
      setIsCartReclaimerInSession(true)
    }
  }, [
    boxTypes,
    cartData?.invoiceItems,
    cartData?.subscription,
    params?.cart,
    setCheckoutIdInSession,
    setInvoiceItems,
    setIsCartReclaimerInSession,
    settings?.defaultFrequency,
    stagedSubscription,
    updateStagedSubscription,
  ])

  const setToast = React.useCallback(() => {
    //we've done our due diligence in the promise checking for cookies and found a valid offer in the checkout-settings
    if (checkedCookies && settings?.offer) {
      fireToast('success', {
        children: 'Congratulations, your special offer has been applied!',
      })
    } else if (
      (params?.coupon || params?.exclusive_offer) &&
      checkedCookies &&
      settings?.offer === null
    ) {
      fireToast('error', {
        children: 'Sorry, your special offer is either expired or invalid!',
      })
    } else if (typeof state?.toast !== 'undefined') {
      fireToast(...state.toast)
    }
  }, [
    checkedCookies,
    settings?.offer,
    params?.coupon,
    params?.exclusive_offer,
    state?.toast,
    fireToast,
  ])

  /**
   * this useEffect iterates through the checkout state graph (STATE_GRAPH) based on the current status of the checkoutActionState (string that matches a key in STATE_GRAPH) and checkoutStatus (useCheckoutPromise fetch status)
   * if the checkoutActionState matches specific criteria to move on to the next stage in the checkout state graph, we run the reducer--updateCheckoutActionState--to tell it to "TOGGLE" the next state. (ex: if state is "success", "TOGGLE" will move the state to "setSubscription")
   * if there is an issue, we tell the reducer to "BREAK" and move to the error state, which esentially ends the state machine and displays an error in plans-and-addons
   * */
  React.useEffect(() => {
    if (checkoutActionState === CHECKOUT_ACTION_STATE.DONE) return
    if (
      (checkoutActionState === CHECKOUT_ACTION_STATE.LOADING &&
        checkoutStatus === CHECKOUT_FETCH_STATE.SUCCESS) ||
      checkoutActionState === CHECKOUT_ACTION_STATE.SUCCESS
    ) {
      updateCheckoutActionState('TOGGLE')
    } else if (checkoutActionState === CHECKOUT_ACTION_STATE.SET_SUB) {
      handleInitialStageSubscription()

      //setInitialBoxType to determine whether or not to show beef; all-beef is currently only accessible to cart reclaimers.
      if (!initialBoxType && stagedSubscription?.box?.type) {
        setInitialBoxType(stagedSubscription?.box?.type)
      }
      updateCheckoutActionState('TOGGLE')
    } else if (checkoutActionState === CHECKOUT_ACTION_STATE.SET_TOAST) {
      setToast()
      updateCheckoutActionState('TOGGLE')
    } else if (
      checkoutActionState === CHECKOUT_ACTION_STATE.LOADING &&
      checkoutStatus === CHECKOUT_ACTION_STATE.ERROR
    ) {
      updateCheckoutActionState('BREAK')
    }
  }, [
    checkoutActionState,
    checkoutStatus,
    handleInitialStageSubscription,
    initialBoxType,
    setInitialBoxType,
    setToast,
    stagedSubscription,
  ])

  return checkoutActionState
}
