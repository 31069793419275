import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  rem,
  Text,
  TextInput,
} from '@butcherbox/freezer'
import { Field, FieldProps, useFormikContext } from 'formik'
import React from 'react'
import * as styles from './ModalEmailOptin.css'
import BackgroundImage, { IBackgroundImageProps } from 'gatsby-background-image'
import { styled } from 'design'

type ModalEmailOptinUIProps = {
  closeFunction: (hasSubmitted?: boolean) => void
  imgOverlayBackground: IBackgroundImageProps['fluid']
}

export function ModalEmailOptinUI({
  imgOverlayBackground,
  closeFunction,
}: ModalEmailOptinUIProps) {
  const formik = useFormikContext<{ email: string }>()
  const showError = formik.submitCount && formik.errors.email

  const PanelImage = styled(BackgroundImage)<{
    backgroundSize?: string
  }>`
    min-height: ${rem(166)};
    width: 100%;
    backgroundsize: ${(p) => p.backgroundSize};

    &:before,
    &:after {
      background-size: ${(p) => p.backgroundSize};
    }
  `

  return (
    <Modal
      isOpen
      onClose={closeFunction}
      size="m"
      title="Save your offer & order"
    >
      <ModalBody alignItems="center" paddingTop={0}>
        <Box
          style={{
            marginRight: rem(-32),
            marginLeft: rem(-32),
            width: 'calc(100% +(32 * 2))',
          }}
        >
          <PanelImage
            aria-hidden
            backgroundSize="cover"
            fadeIn={false}
            fluid={imgOverlayBackground}
            style={{ backgroundSize: '' }} // Allows property to be overridden
          />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          marginTop={16}
        >
          <Text marginTop={8} variant="H2Bold">
            Claim your <span style={{ textTransform: 'uppercase' }}>free</span>{' '}
            offer!
          </Text>
          <Text variant="Body1Regular">
            Enter your email to receive this offer in your first box.
          </Text>
          <Box className={styles.EmailInput} marginTop={16}>
            <Field name="email" type="email" valida>
              {({ field }: FieldProps) => (
                <TextInput
                  id="email"
                  label="Email"
                  messages={
                    showError
                      ? [{ tone: 'critical', message: formik.errors.email }]
                      : []
                  }
                  {...field}
                />
              )}
            </Field>
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button
          loading={formik.isSubmitting}
          loadingText="Loading"
          my={12}
          size="small"
          type="submit"
        >
          {'Save your offer & order'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
