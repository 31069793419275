import { useQuery } from 'react-query'
import { CACHE_KEY_CHECKOUT_DEALS } from '~/bb-api/constants'
import { GET_CHECKOUT_DEALS } from '~/bb-api/endpoints'
import { BaseDeal } from '~/bb-api/schemata'
import axios from '~/utils/axios'

export default function useCheckoutDeals() {
  return useQuery([CACHE_KEY_CHECKOUT_DEALS], () =>
    axios.get(GET_CHECKOUT_DEALS).then((response) => {
      const data = response.data?.data ?? []

      return data as BaseDeal[]
    })
  )
}
