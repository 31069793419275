import React from 'react'
import { BoxSizeDefinition } from '~/bb-api/schemata'
import { TEST_ID } from '~/constants/cypress'
import { BoxSizeCardRadioUI } from '~/routes/CheckoutFlow/BoxSizeCardRadio/BoxSizeCardRadio.ui'
import { getDescriptionForBoxSize } from '~/routes/CheckoutFlow/BoxSizeFrequencyFrame/boxSizeFrequencyFrame.helper'
import { formatPriceWithoutTrailingZeroes } from '~/utils'
import { humanizeBoxSize } from '~/utils/humanize'

export const BoxSizeCardRadio = ({
  badge,
  definition,
}: {
  badge: string
  definition: BoxSizeDefinition
}) => {
  const humanizedBoxSize = humanizeBoxSize(definition?.size)
  const price = formatPriceWithoutTrailingZeroes(definition?.price)
  const description = getDescriptionForBoxSize(definition?.size)
  const bulletText = `${definition?.poundage} of high quality meat`

  return (
    <BoxSizeCardRadioUI
      badge={badge}
      bulletText={bulletText}
      data-cy={TEST_ID.CHECKOUT_FLOW_BOX_SIZE}
      data-cy-box-size={definition.size}
      description={description}
      formattedPrice={price}
      humanizedSizeName={humanizedBoxSize}
      size={definition?.size}
    />
  )
}
