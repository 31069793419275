import GatsbyImage, { GatsbyImageFluidProps } from 'gatsby-image'
import React from 'react'
import { QuoteIcon } from '../shared'
import { Box, Text, rem } from '@butcherbox/freezer'
import * as Styles from './Testimonial.css'
interface TestimonialProps {
  content: React.ReactChild
  heading: React.ReactChild
  image: GatsbyImageFluidProps['fluid']
  subheading: React.ReactChild
}

export const Testimonial = ({
  heading,
  subheading,
  content,
  image,
}: TestimonialProps) => (
  <Box className={Styles.Testimonial}>
    <Box className={Styles.PhotoBox}>
      <GatsbyImage fadeIn={false} fluid={image} />
    </Box>
    <Box display="inlineBlock" flexGrow={1}>
      <Box display="inlineFlex">
        <QuoteIcon
          height={{ base: rem(31), tablet: rem(40) }}
          style={{ flexShrink: 0 }}
        />
        <Box paddingLeft={8} paddingTop={4}>
          <Text component="h3" variant="H4Condensed">
            {heading}
          </Text>
          <Text component="p" variant="Body1Regular">
            {subheading}
          </Text>
        </Box>
      </Box>
      <Text component="p" variant="Body1Regular">
        {content}
      </Text>
    </Box>
  </Box>
)
