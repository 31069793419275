import { Box } from '@butcherbox/freezer'

import React from 'react'
import { renderWhenStagedSubscriptionAvailable } from '~/context/stagedSubscription'
import * as Styles from '~/routes/CheckoutFlow/ProgressBar/ProgressBar.css'
import { ProgressBarFillLevel } from './ProgressBarFillLevel'
export const ProgressBar = renderWhenStagedSubscriptionAvailable(
  ({ mounted }) => {
    return (
      <Box className={Styles.ProgressBar}>
        {mounted && <ProgressBarFillLevel />}
      </Box>
    )
  }
)
