import { RouteComponentProps } from '@reach/router'
import { Formik } from 'formik'
import Cookies from 'js-cookie'
import React from 'react'
import { trackCheckoutStepCompleted, trackPlanChosen } from '~/analytics/events'
import { CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS } from '~/bb-api/constants'
import { BoxTypeDefinition, NewSubscription } from '~/bb-api/schemata'
import { CheckoutStateContext } from '~/context/checkoutState'
import { StagedSubscriptionContext } from '~/context/stagedSubscription'
import mutateCheckoutCart from '~/hooks/mutateCheckoutCart'
import useCheckoutSettings from '~/hooks/useCheckoutSettings'
import { useSessionStorage } from '~/hooks/useStorage'
import {
  COOKIE_OFFER_IDENTIFIER,
  Frames,
  SESSION_STORAGE_CHECKOUT_ID_KEY,
} from '~/routes/CheckoutFlow/constants'
import { curatedMeatGroupSet } from '../types'
import {
  curatedBoxSchema,
  curatedMeatTypes,
  noBeefCuratedMeatTypes,
} from './CuratedMeatTypeSelectionFrame.constants'
import { CuratedMeatTypeSelectionFrameUI } from './CuratedMeatTypeSelectionFrame.UI'

export const CuratedMeatTypeSelectionFrame = ({
  navigate,
  shouldShowAllBeef,
  boxTypes,
}: RouteComponentProps & {
  shouldShowAllBeef: boolean
  boxTypes: BoxTypeDefinition[]
}) => {
  const { stagedSubscription, updateStagedSubscription } = React.useContext(
    StagedSubscriptionContext
  )
  const { invoiceItems } = React.useContext(CheckoutStateContext)

  const initialCuratedSelection = curatedMeatGroupSet.has(
    stagedSubscription.box.type
  )
    ? stagedSubscription.box.type
    : 'beef_chicken_pork'

  const [mutateCart] = mutateCheckoutCart('CuratedMeatTypeSelectionFrame')

  const [sessionCheckoutId] = useSessionStorage(
    SESSION_STORAGE_CHECKOUT_ID_KEY,
    null
  )
  const { data: checkoutSettings } = useCheckoutSettings([
    CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS,
    {
      offerId: Cookies.get(COOKIE_OFFER_IDENTIFIER),
    },
  ])
  const checkoutId = sessionCheckoutId || checkoutSettings?.checkoutId

  React.useEffect(() => {
    if (!stagedSubscription.box.type) {
      navigate(Frames.BoxTypeSelectionFrame)
    } else if (!stagedSubscription.box.size) {
      navigate(Frames.BoxSizeFrequencyFrame)
    } else if (stagedSubscription.box.type === 'cst') {
      navigate(Frames.CustomizeBoxFrame)
    }
  }, [stagedSubscription.box.type, stagedSubscription.box.size, navigate])

  const handleSubmit = React.useCallback(
    async (values) => {
      const stagedNewSubscription = stagedSubscription as NewSubscription
      const matchingDefinition = boxTypes.find(
        (boxDefinition) => boxDefinition.type === values.meatGroup
      )

      const updatedStagedSubscription = {
        ...stagedNewSubscription,
        box: {
          ...stagedNewSubscription.box,
          name: matchingDefinition.name,
          type: matchingDefinition.type,
        },
      }

      updateStagedSubscription(updatedStagedSubscription)

      // track box
      trackPlanChosen(matchingDefinition.type, matchingDefinition.name)

      if (checkoutId) {
        trackCheckoutStepCompleted({ checkout_id: checkoutId, step: 2 })
      }

      mutateCart({
        invoiceItems,
        subscription: updatedStagedSubscription,
      })
      navigate(Frames.DealsFrame)
    },
    [
      stagedSubscription,
      boxTypes,
      updateStagedSubscription,
      checkoutId,
      mutateCart,
      invoiceItems,
      navigate,
    ]
  )

  return (
    <Formik
      initialValues={{
        meatGroup: initialCuratedSelection,
      }}
      onSubmit={handleSubmit}
      validationSchema={curatedBoxSchema}
    >
      {({ isSubmitting }) => (
        <CuratedMeatTypeSelectionFrameUI
          buttonText={'Next, Select Deals'}
          curatedMeatTypes={
            shouldShowAllBeef ? curatedMeatTypes : noBeefCuratedMeatTypes
          }
          isSubmitting={isSubmitting}
        />
      )}
    </Formik>
  )
}
