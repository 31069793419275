import { boxFrequencyValue } from '~/routes/CheckoutFlow/BoxSizeFrequencyFrame/BoxSizeFrequency.types'

export const boxFrequencyValues: boxFrequencyValue[] = [
  {
    inputProps: {
      autoFocus: true,
    },
    label: 'Every 4 weeks',
    value: 'every_month',
  },
  {
    label: 'Every 6 weeks',
    value: 'every_42_days',
  },
  {
    label: 'Every 8 weeks',
    value: 'every_2_months',
  },
]
