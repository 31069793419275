import React from 'react'
import type { BoxType } from '~/bb-api/schemata'
import { Frames } from '~/routes/CheckoutFlow/constants'
import { curatedMeatGroupSet } from '~/routes/CheckoutFlow/types'

export function useNextButtonProps(boxType: BoxType) {
  return React.useMemo(() => {
    if (boxType === 'cst') {
      return {
        nextFrame: Frames.CustomizeBoxFrame,
        continueButtonText: 'Next, select cuts',
      }
    }

    if (curatedMeatGroupSet.has(boxType)) {
      return {
        nextFrame: Frames.CuratedMeatTypeSelectionFrame,
        continueButtonText: 'Next, choose your proteins',
      }
    } else {
      return {
        nextFrame: Frames.DealsFrame,
        continueButtonText: 'Next, select deals',
      }
    }
  }, [boxType])
}
