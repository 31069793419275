import type { CardBadgeProps } from '@butcherbox/freezer'
import { BoxItem } from '~/bb-api/schemata'

/**
 * @deprecated Prefer the more human-readable versions instead
 */
type DeprecatedBadgeType = 'bestValue' | 'greatDeal'

export type BadgeType =
  | 'Best Value'
  | 'Great Deal'
  | '% off'
  | '$ off'
  | 'Bundle & Save'
  | DeprecatedBadgeType

export function isValidBadge(badge?: string | null): badge is BadgeType {
  switch (badge) {
    case 'Best Value':
    case 'Great Deal':
    case '% off':
    case '$ off':
    case 'Bundle & Save':
    case 'bestValue':
    case 'greatDeal':
      return true
    default:
      return false
  }
}

export default function makeCardBadgeProps(
  badge: { type: BadgeType } | null,
  product: {
    groupedProducts?: BoxItem[] | null
    price: number
    strikethroughPrice: number | null
  }
): CardBadgeProps | null {
  if (!badge) return null
  const badgeType = badge.type // alias the variable so the value can be narrowed by TS
  // render price-unaware badge if requested
  switch (badgeType) {
    case 'greatDeal':
    case 'Great Deal':
      return { variant: 'GreatDeal', position: 'tl', text: 'Great Deal' }
    case 'bestValue':
    case 'Best Value':
      return { variant: 'BestValue', position: 'tl', text: 'Best Value' }
  }

  if (!product.price || !product.strikethroughPrice) {
    // all other badges require both `price` and `strikethroughPrice`
    return null
  }

  if (product.price > product.strikethroughPrice) return null // all remaining badges only make sense for products that are "on sale"

  switch (badgeType) {
    case '$ off': {
      const text = renderDiscount(
        product.price,
        product.strikethroughPrice,
        '$'
      )
      if (!text) return null
      return {
        variant: 'text',
        theme: 'spicedCrimson',
        position: 'tl',
        text: `${text} OFF`,
      }
    }
    case '% off': {
      const text = renderDiscount(
        product.price,
        product.strikethroughPrice,
        '%'
      )
      if (!text) return null
      return {
        variant: 'text',
        theme: 'openSea',
        position: 'tl',
        text: `${text} OFF`,
      }
    }
    case 'Bundle & Save': {
      const text = renderDiscount(
        product.price,
        product.strikethroughPrice,
        '$',
        false
      )
      if (!text) return null
      return {
        position: 'tl',
        variant: 'DotWhack',
        theme: 'spicedCrimson',
        text: `Bundle & Save ${text}`,
      }
    }

    default: {
      const _exhaustiveCheck: never = badgeType
      return _exhaustiveCheck
    }
  }
}

function renderDiscount(
  price: number,
  strikethroughPrice: number,
  format: '%' | '$',
  includeTrailingZeroes: boolean = true
) {
  if (
    strikethroughPrice <= 0.0 ||
    price >= strikethroughPrice ||
    Number.isNaN(price) ||
    Number.isNaN(strikethroughPrice)
  ) {
    return null
  }

  switch (format) {
    case '%':
      return `${Math.floor(
        ((strikethroughPrice - price) / strikethroughPrice) * 100
      )}%`
    case '$': {
      const savingsValue = (strikethroughPrice - price) / 100
      const isEvenDollarValue = savingsValue % 1 === 0
      const toFixedOffset = includeTrailingZeroes || !isEvenDollarValue ? 2 : 0
      return `$${((strikethroughPrice - price) / 100).toFixed(toFixedOffset)}`
    }
    default: {
      const _exhaustiveCheck: never = format
      return _exhaustiveCheck
    }
  }
}
