import { VisuallyHidden, rem, Text } from '@butcherbox/freezer'
import {
  CardRadioContent,
  StyledLearnMoreListParent,
  StyledRadioCardTitleContainer,
} from 'design/components/CardRadio/CardRadio.styles'
import {
  CardRadioExpander,
  CardRadioFormik,
  CardRadioTitle,
  SelectableRadioComponent,
} from 'design/components/CardRadio/CardRadioComponents'
import { LISimpleCheck, LISimpleX, List } from 'design/components/List/List'
import { Field, FieldProps } from 'formik'
import React from 'react'
import { BoxTypeDefinition } from '~/bb-api/schemata'
import { TEST_ID } from '~/constants/cypress'

export type PerkList = Array<{
  status: 'included' | 'not_included'
  perkDescription: string
}>

export interface MinimalPlanTypeCardUIProps {
  typeDefinition: BoxTypeDefinition
  perkList?: PerkList
}

export const MinimalPlanTypeCardUI = (props: MinimalPlanTypeCardUIProps) => {
  return (
    <Field
      component={MinimalPlanTypeCardUIComponent}
      name="boxType"
      type="radio"
      value={props?.typeDefinition?.type}
      {...props}
    />
  )
}

export const MinimalPlanTypeCardUIComponent = ({
  typeDefinition,
  field,
  form,
  perkList,
}: MinimalPlanTypeCardUIProps & FieldProps<any>) => {
  const radioId = `${typeDefinition?.name}-radio`
  const expanderSectionId = typeDefinition?.name

  return (
    <CardRadioFormik field={field}>
      <CardRadioContent>
        <StyledRadioCardTitleContainer
          data-cy={TEST_ID.CHECKOUT_FLOW_PLAN_TYPE_CARD}
          data-cy-checkout-plan-type={typeDefinition?.name}
          hasMarginBottom
        >
          <CardRadioTitle radioId={radioId} title={typeDefinition?.name} />
          <SelectableRadioComponent
            data-what={`${typeDefinition?.name} Radio`}
            data-where="checkout-plan-selection"
            field={field}
            form={form}
            id={radioId}
          />
        </StyledRadioCardTitleContainer>
        {typeDefinition?.description}
      </CardRadioContent>
      <CardRadioExpander
        ctaText="Learn More"
        data-cy={TEST_ID.CHECKOUT_FLOW_LEARN_MORE}
        data-cy-checkout-plan-type={typeDefinition?.name}
        uniqueId={expanderSectionId}
      >
        <StyledLearnMoreListParent>
          <PlanTypeCardPerkList perkList={perkList} />
        </StyledLearnMoreListParent>
      </CardRadioExpander>
    </CardRadioFormik>
  )
}

interface PlanTypeCardPerkListProps {
  perkList: PerkList
}

export const PlanTypeCardPerkList = ({
  perkList,
}: PlanTypeCardPerkListProps) => {
  return perkList?.length > 0 ? (
    <List
      as="ul"
      columnGap={rem(12)}
      data-cy={TEST_ID.CHECKOUT_FLOW_PERK_LIST}
      gridTemplateColumns="auto 1fr"
      paddingBottom={rem(16)}
      paddingTop={rem(8)}
      rowGap={rem(4)}
    >
      {perkList.map(({ status, perkDescription }) => {
        return status === 'included' ? (
          <LISimpleCheck key={perkDescription} size={rem(16)}>
            <>
              <VisuallyHidden>Included:</VisuallyHidden>
              <Text variant="Body1Regular">{perkDescription}</Text>
            </>
          </LISimpleCheck>
        ) : (
          <LISimpleX color="bb.stone" key={perkDescription} size={rem(16)}>
            <>
              <VisuallyHidden>Not included:</VisuallyHidden>
              <Text variant="Body1Regular">{perkDescription}</Text>
            </>
          </LISimpleX>
        )
      })}
    </List>
  ) : null
}
