import React from 'react'
import {
  trackCheckoutDealAdded,
  trackCheckoutDealRemoved,
} from '~/analytics/events'
import {
  BaseDeal,
  BoxType,
  Deal,
  GroupedBoxItem,
  LanderDeal,
} from '~/bb-api/schemata'
import { useSessionStorage } from '~/hooks/useStorage'

const SESSION_STORAGE_CHECKOUT_STATE_KEY = 'checkout_state'

/**
 * @todo Remove all uses of basic box from logic & test cases before removing it from enum
 */
export enum BoxCategories {
  BASIC = 'basic',
  CUSTOM = 'cst',
  CURATED = 'curated',
}

interface CheckoutState {
  extras: Array<Pick<GroupedBoxItem, 'sku' | 'quantity'>>
  invoiceItems: Array<GroupedBoxItem>
  initialBoxCategory: BoxCategories | null
  initialBoxType: BoxType
}

interface CheckoutStateFunctions {
  addExtra: (sku: string, quantity: number) => void
  decrementInvoiceItem: (
    product: LanderDeal | Deal | BaseDeal,
    quantity: number,
    index: number
  ) => () => void
  incrementInvoiceItem: (
    product: LanderDeal | Deal | BaseDeal,
    quantity: number,
    index: number
  ) => () => void
  setInvoiceItems: (newInvoiceItems: Array<Partial<GroupedBoxItem>>) => void
  setInitialBoxCategory: (boxCategory: BoxCategories) => void
  setInitialBoxType: (boxType: BoxType) => void
}

type CheckoutStateContext = CheckoutState & CheckoutStateFunctions

export const CheckoutStateContext = React.createContext<CheckoutStateContext>(
  undefined
)

export const CheckoutStateProvider = ({ children }) => {
  const [
    checkoutState,
    setStoredCheckoutState,
  ] = useSessionStorage<CheckoutState>(SESSION_STORAGE_CHECKOUT_STATE_KEY, {
    extras: [],
    invoiceItems: [],
    initialBoxCategory: null,
    initialBoxType: null,
  })
  const {
    extras,
    invoiceItems,
    initialBoxCategory,
    initialBoxType,
  } = checkoutState
  /**
   * This function is currently only used for the exit intent modal.
   * We have opted not to implement the normal increment/decrement functionality
   * of the other product types to prevent it from being used incorrectly in
   * other locations.
   */
  function addExtra(sku: string, quantity: number) {
    setStoredCheckoutState({
      ...checkoutState,
      extras: [{ sku, quantity }],
    })
  }

  function incrementInvoiceItem(
    product: Deal,
    quantity: number,
    index: number
  ) {
    return () => {
      trackCheckoutDealAdded(product, index + 1, quantity + 1)

      let newInvoiceItems = invoiceItems.slice()

      const item = newInvoiceItems.find((x) => x.sku === product.sku)

      if (item) {
        item.quantity += 1
      } else {
        newInvoiceItems.push({ ...product, amount: 0, quantity: 1 })
      }

      // ensure quantities of zero are not making it into the staged subscription
      newInvoiceItems = newInvoiceItems.filter((x) => x.quantity > 0)

      setStoredCheckoutState({
        ...checkoutState,
        invoiceItems: newInvoiceItems,
      })
    }
  }

  function decrementInvoiceItem(product: Deal, index, quantity) {
    return () => {
      trackCheckoutDealRemoved(product, index + 1, quantity - 1)
      let newInvoiceItems = invoiceItems.slice()

      const item = newInvoiceItems.find((x) => x.sku === product.sku)

      if (item) {
        item.quantity -= 1
      }

      // ensure quantities of zero are not making it into the staged subscription
      newInvoiceItems = newInvoiceItems.filter((x) => x.quantity > 0)

      setStoredCheckoutState({
        ...checkoutState,
        invoiceItems: newInvoiceItems,
      })
    }
  }

  function setInvoiceItems(newInvoiceItems: Array<GroupedBoxItem>) {
    setStoredCheckoutState({
      ...checkoutState,
      invoiceItems: newInvoiceItems,
    })
  }

  function setInitialBoxCategory(boxCategory: BoxCategories) {
    setStoredCheckoutState({
      ...checkoutState,
      initialBoxCategory: boxCategory,
    })
  }

  function setInitialBoxType(boxType: BoxType) {
    setStoredCheckoutState({
      ...checkoutState,
      initialBoxType: boxType,
    })
  }

  return (
    <CheckoutStateContext.Provider
      value={{
        addExtra,
        decrementInvoiceItem,
        extras,
        invoiceItems,
        incrementInvoiceItem,
        setInvoiceItems,
        initialBoxCategory,
        initialBoxType,
        setInitialBoxCategory,
        setInitialBoxType,
      }}
    >
      {children}
    </CheckoutStateContext.Provider>
  )
}
