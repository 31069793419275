import * as yup from 'yup'
import { CuratedMeatTypes } from '~/routes/CheckoutFlow/types'

export const curatedMeatTypes: CuratedMeatTypes[] = [
  { meatGroup: 'beef_chicken_pork', title: 'Beef, Pork & Chicken' },
  { meatGroup: 'beef_and_pork', title: 'Beef & Pork' },
  { meatGroup: 'beef_and_chicken', title: 'Beef & Chicken' },
  { meatGroup: 'all_beef', title: 'All Beef' },
]

export const noBeefCuratedMeatTypes: Omit<CuratedMeatTypes[], 'all_beef'> = [
  { meatGroup: 'beef_chicken_pork', title: 'Beef, Pork & Chicken' },
  { meatGroup: 'beef_and_pork', title: 'Beef & Pork' },
  { meatGroup: 'beef_and_chicken', title: 'Beef & Chicken' },
]

//As part of JOIN-360, the removal of all_beef is being tested in curated flow
export enum JOIN_360_VARIANTS {
  CONTROL = 'control',
  NO_BEEF = 'no_beef',
}

export const curatedBoxSchema = yup.object().shape({
  meatGroup: yup
    .string()
    .required('You must select your choice of meat to continue'),
})
