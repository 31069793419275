import { Flex } from '@chakra-ui/core'
import { rem } from 'design'
import React from 'react'
import {
  MOBILE_X_PADDING,
  TABLET_X_PADDING,
} from '~/routes/CheckoutFlow/constants'

export const Header = ({ children, ...props }) => (
  <Flex
    align="center"
    h={rem(56)}
    justify="space-between"
    px={{ base: rem(MOBILE_X_PADDING), tablet: rem(TABLET_X_PADDING) }}
    {...props}
  >
    {children}
  </Flex>
)
