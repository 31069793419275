import { useLocation } from '@reach/router'
import Cookies from 'js-cookie'
import qs from 'querystringify'
import React from 'react'
import { trackCheckoutMarketingOptIn, waitFor } from '~/analytics/events'
import { segmentIdentify } from '~/analytics/identifiers'
import { CHECKOUT_EMAIL } from '~/bb-api/endpoints'
import { getCheckoutSettings } from '~/hooks/useCheckoutSettings'
import axios from '~/utils/axios'
import { COOKIE_LANDER_OFFER_ID } from '~/utils/lander'
import { captureNetworkError } from '~/analytics/errors'

export type UTMQueryParameters = {
  utm_campaign?: string
  utm_content?: string
  utm_medium?: string
  utm_source?: string
  utm_term?: string
}

export const useUTMParams = () => {
  const location = useLocation()
  const qparams = React.useMemo<UTMQueryParameters>(
    () => qs.parse(location?.search),
    [location?.search]
  )

  return qparams
}

type MutatorVariables = {
  email: string
  queryParams: UTMQueryParameters
}

export const startCheckoutMutator = ({
  email,
  queryParams,
}: MutatorVariables) => {
  return Promise.all([
    axios.post(CHECKOUT_EMAIL, {
      email,
      utmCampaign: queryParams.utm_campaign || null,
      utmContent: queryParams.utm_content || null,
      utmMedium: queryParams.utm_medium || null,
      utmSource: queryParams.utm_source || null,
      utmTerm: queryParams.utm_term || null,
    }),

    trackStartCheckout(email, queryParams),
  ])
}

export const trackStartCheckout = async (
  email: string,
  queryParams: UTMQueryParameters
) => {
  try {
    const checkoutSettings = await getCheckoutSettings()

    // identify before track so that the email is available for klaviyo eventing
    await new Promise<void>((resolve) => {
      const timeoutId = window.setTimeout(() => {
        resolve()
      }, 5000)

      segmentIdentify({
        traits: {
          email,
        },
        callback: () => {
          window.clearTimeout(timeoutId)
          resolve()
        },
      })
    })

    await waitFor(trackCheckoutMarketingOptIn, { timeout: 5000 })({
      couponCode: checkoutSettings.discountCode,
      email,
      offer: checkoutSettings.offer,
      offerId: Cookies.get(COOKIE_LANDER_OFFER_ID),
      utmCampaign: queryParams.utm_campaign || '',
      utmContent: queryParams.utm_content || '',
      utmMedium: queryParams.utm_medium || '',
      utmSource: queryParams.utm_source || '',
      utmTerm: queryParams.utm_term || '',
    })
  } catch (e) {
    captureNetworkError(e)
  }
}
