import type { BoxSizeDefinition } from '~/bb-api/schemata'

export const getDescriptionForBoxSize = (size: BoxSizeDefinition['size']) => {
  switch (size) {
    case 'classic': {
      return 'Good for individuals and small families'
    }

    case 'big': {
      return 'Good for mid-size families and large freezers'
    }
  }
}
