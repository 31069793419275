import {
  CardRadioContainer,
  StyledCardRadioExpander,
  StyledCardRadioTitle,
  StyledExpanderButton,
} from 'design/components/CardRadio/CardRadio.styles'
import type { CardRadioProps } from 'design/components/CardRadio/CardRadioComponents.types'
import Radio from 'design/components/Radio/Radio'
import { IconPlus } from '@butcherbox/freezer'
import {
  H2Bold,
  H2Book,
  SubheadSmaller,
} from 'design/components/Typography/Headlines'
import { FieldInputProps, FormikProps, useFormikContext } from 'formik'
import React from 'react'
import { getAllOptions } from 'design/helpers/getAllOptions'
import {
  radioKeyboardNavLeft,
  radioKeyboardNavRight,
} from 'design/helpers/radioKeyboardNav'

export function CardRadioFormik({
  children,
  field,
  ...props
}: {
  children: React.ReactNode
  field: FieldInputProps<any>
}) {
  const { setFieldValue, submitForm } = useFormikContext()
  const cardRef = React.useRef(null)

  return (
    <CardRadio
      onClick={() => setFieldValue(field.name, field.value)}
      onKeyDown={(evt) => {
        if (evt.key === ' ') {
          evt.preventDefault()
        }
      }}
      onKeyUp={(evt) => {
        const nodeArr = getAllOptions(cardRef)
        const currentIndex = Array.prototype.indexOf.call(
          nodeArr,
          cardRef.current.querySelector('[data-option]')
        )
        if (evt.key === 'ArrowRight' || evt.key === 'ArrowDown') {
          radioKeyboardNavRight(nodeArr, currentIndex, setFieldValue)
        } else if (evt.key === ' ') {
          evt.currentTarget.click()
        } else if (evt.key === 'Enter') {
          submitForm()
        } else if (evt.key === 'ArrowLeft' || evt.key === 'ArrowUp') {
          radioKeyboardNavLeft(nodeArr, currentIndex, setFieldValue)
        }
      }}
      ref={cardRef}
      selected={field.checked}
      tabIndex={-1}
      {...props}
    >
      {children}
    </CardRadio>
  )
}

export const CardRadio = React.forwardRef(
  ({ children, selected, ...props }: CardRadioProps, ref) => {
    return (
      <CardRadioContainer
        className={selected ? 'selected' : ''}
        ref={ref}
        {...props}
      >
        {children}
      </CardRadioContainer>
    )
  }
)

export const CardRadioTitle = ({
  title,
  secondaryText,
  radioId,
}: {
  title: string
  secondaryText?: string
  radioId: string
}) => {
  return (
    <StyledCardRadioTitle as="label" cursor="inherit" htmlFor={radioId}>
      <H2Bold as="span" color="bb.slate">
        {title}
      </H2Bold>
      <H2Book as="span" color="bb.spicedCrimson">
        {secondaryText}
      </H2Book>
    </StyledCardRadioTitle>
  )
}

export const SelectableRadioComponent = ({
  field,
  form,
  id,
  errorStyleOverride,
  ...props
}: {
  field: FieldInputProps<any>
  form: FormikProps<any>
  id: string
  errorStyleOverride?: React.CSSProperties
}) => {
  return (
    <Radio
      as="div"
      data-option
      disableTabIndex={true}
      errorStyleOverride={errorStyleOverride}
      field={field}
      form={form}
      inputProps={{ id, ...props }}
      size="lg"
      tabIndex={0}
    />
  )
}

export const CardRadioExpander = ({
  ctaText,
  children,
  uniqueId = '',
  ...props
}: {
  ctaText: string
  children: React.ReactNode
  uniqueId?: string
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const expanderId = `expander-section-${uniqueId}`

  const clickToggleExpanded = (
    evt: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    // If a user wants to see details on a card, we don't necessarily want to select the item
    // so we prevent the click/key event from bubbling up to our form handlers
    evt.stopPropagation()
    setIsExpanded(!isExpanded)
  }

  const keyToggleExpanded = (evt: React.KeyboardEvent<HTMLElement>) => {
    evt.stopPropagation()
    if (evt.key === 'enter' || evt.key === ' ') {
      evt.currentTarget.click()
    }
  }

  return (
    <StyledCardRadioExpander
      aria-controls={expanderId}
      aria-expanded={isExpanded}
      className={isExpanded ? 'expanded' : ''}
      onClick={(e) => e.stopPropagation()}
    >
      <StyledExpanderButton
        onClick={clickToggleExpanded}
        onKeyUp={keyToggleExpanded}
        type="button"
      >
        <SubheadSmaller as="span" color="bb.spicedCrimson" {...props}>
          {ctaText}
        </SubheadSmaller>
        <IconPlus customColor={{ base: 'spicedCrimson' }} size="text" />
      </StyledExpanderButton>
      {isExpanded ? <div id={expanderId}>{children}</div> : null}
    </StyledCardRadioExpander>
  )
}
