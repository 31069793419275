import { Box, Button } from '@butcherbox/freezer'
import React from 'react'
import { EmailOptinModalContext } from '~/experiments/JOIN-439-email-optin-modal-experiments/emailOptinModalContext'

export const ModalEmailOptinTrigger = () => {
  const { state } = React.useContext(EmailOptinModalContext)

  return (
    <Box textAlign="center">
      <Button onClick={state.open} type="button" variant="text">
        save your offer & order
      </Button>
    </Box>
  )
}
