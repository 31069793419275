import { rem, VisuallyHidden } from '@butcherbox/freezer'
import { Box } from '@chakra-ui/core'
import { RouteComponentProps } from '@reach/router'
import immutableSet from 'clean-set'
import React from 'react'
import { trackError } from '~/analytics/errors'
import {
  trackCheckoutStepCompleted,
  trackSignupCustomCutsChosen,
} from '~/analytics/events'
import * as schemata from '~/bb-api/schemata'
import { BoxSize, NewSubscription } from '~/bb-api/schemata'
import BaseCustomize from '~/components/BaseCustomize/BaseCustomize'
import { TEST_ID } from '~/constants/cypress'
import { CheckoutStateContext } from '~/context/checkoutState'
import { StagedSubscriptionContext } from '~/context/stagedSubscription'
import mutateCheckoutCart from '~/hooks/mutateCheckoutCart'
import useBoxItems from '~/hooks/useBoxItems'
import { useSessionStorage } from '~/hooks/useStorage'
import { Frames, SESSION_STORAGE_CHECKOUT_ID_KEY } from './constants'

const CustomizeBoxFrame: React.FC<RouteComponentProps> = ({ navigate }) => {
  const { stagedSubscription, updateStagedSubscription } = React.useContext(
    StagedSubscriptionContext
  )

  const { invoiceItems } = React.useContext(CheckoutStateContext)

  const {
    data: rawProductData = {} as schemata.AvailableCustomCuts,
    status: getBoxItemsStatus,
  } = useBoxItems()

  const [sessionCheckoutId] = useSessionStorage(
    SESSION_STORAGE_CHECKOUT_ID_KEY,
    null
  )

  const checkoutId = sessionCheckoutId
  const [mutateCart] = mutateCheckoutCart('CustomizeBoxFrame')

  React.useEffect(() => {
    if (!stagedSubscription.box.size) {
      updateStagedSubscription(
        immutableSet(stagedSubscription, 'box.size', 'classic' as BoxSize)
      )
    }
  }, [stagedSubscription, updateStagedSubscription])

  const handleSave = React.useCallback(
    async (updatedSubscription: NewSubscription) => {
      updateStagedSubscription(updatedSubscription)
      trackSignupCustomCutsChosen(updatedSubscription.box.items)

      if (checkoutId) {
        trackCheckoutStepCompleted({ checkout_id: checkoutId, step: 2 })
        mutateCart({
          invoiceItems,
          subscription: updatedSubscription,
        })
      }

      if (navigate) {
        navigate(Frames.DealsFrame)
      } else {
        trackError((scope) => {
          scope.capture(
            new Error(
              'navigate function not available. Failed to navigate away from CustomizeBoxFrame.'
            )
          )
        })
      }
    },
    [updateStagedSubscription, checkoutId, navigate, mutateCart, invoiceItems]
  )

  return (
    <Box paddingBottom={rem(100)}>
      <h1 data-cy={TEST_ID.CHECKOUT_FLOW_BOX_CUSTOMIZE}>
        <VisuallyHidden>
          ButcherBox Checkout: Customize Your Cuts of Meat
        </VisuallyHidden>
      </h1>

      <BaseCustomize
        getBoxItemsStatus={getBoxItemsStatus}
        handleSave={handleSave}
        handleSaveStatus="synchronous"
        isLoading={getBoxItemsStatus === 'loading'}
        productListCategory="customBox-checkoutCustomizeYourBox"
        rawAvailableProducts={rawProductData}
        saveButtonText="Next, Select Deals"
        showHeaderText={true}
        sidebarSaveButtonText={'Next, Select Deals'}
      />
    </Box>
  )
}

export default CustomizeBoxFrame
