import { Text } from '@butcherbox/freezer'
import { Box, Grid } from '@chakra-ui/core'
import { RouteComponentProps } from '@reach/router'
import { rem } from 'design'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import DealCard from '~/routes/CheckoutFlow/MemberDealsFrame/DealCard/DealCard'
import { MemberDealsFrameUIProps } from '~/routes/CheckoutFlow/MemberDealsFrame/MemberDealsFrame.types'
import {
  CheckoutButtonLink,
  NextFrameButtonContainer,
} from '~/routes/CheckoutFlow/shared'
import * as Styles from '~/routes/CheckoutFlow/MemberDealsFrame/MemberDealsFrame.css'

const MemberDealsFrameUI: React.FC<
  RouteComponentProps & MemberDealsFrameUIProps
> = ({
  decrementInvoiceItem,
  incrementInvoiceItem,
  isLoading,
  deals,
  handleTracking,
  invoiceItemMap,
  buttonText,
  nextFrame,
}) => {
  return (
    <Box
      boxSizing="content-box"
      className={Styles.MemberDealsFrame}
      data-cy={TEST_ID.CHECKOUT_FLOW_DEALS}
      mx="auto"
      textAlign="center"
    >
      <Text component="h1" variant="H2Bold">
        Select your member deals
      </Text>
      <Text variant="Body1Regular">
        Member deals offer the best value! They only ship once and are available
        in limited quantities for a limited time.
      </Text>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Grid
          as="ul"
          gridGap={rem(24)}
          gridTemplateColumns={{
            base: '1fr',
            tablet: '1fr 1fr 1fr',
          }}
          justifyContent="center"
          justifyItems="center"
          margin={`${rem(24)} auto`}
          maxWidth={rem(1008)}
        >
          {deals.map((deal, idx) => (
            <DealCard
              as="li"
              data-sku={deal.sku}
              data-what="checkout-deal-card"
              deal={deal}
              decrementInvoiceItem={decrementInvoiceItem}
              displayIndex={idx}
              incrementInvoiceItem={incrementInvoiceItem}
              invoiceItemMap={invoiceItemMap}
              key={deal.sku}
            />
          ))}
        </Grid>
      )}

      <NextFrameButtonContainer>
        <CheckoutButtonLink
          data-cy={TEST_ID.CHECKOUT_FLOW_NEXT_FRAME}
          onClick={handleTracking}
          to={nextFrame}
        >
          {buttonText}
        </CheckoutButtonLink>
      </NextFrameButtonContainer>
    </Box>
  )
}

export default MemberDealsFrameUI
