import { Frames } from '~/routes/CheckoutFlow/constants'

export const frameData = (pathname?: string) => {
  // If we're on the plans and addons page, we need to know how many frames there are, which is dependent on the experiment variation
  const totalFrames = 5

  const allFramesObj = {
    ['/plans-and-addons/']: {
      totalFrames,
      frameNumber: 1,
    },
    [Frames.BoxTypeSelectionFrame]: {
      totalFrames,
      frameNumber: 1,
    },
    [Frames.BoxSizeFrequencyFrame]: {
      totalFrames,
      frameNumber: 2,
    },
    [Frames.CustomizeBoxFrame]: {
      totalFrames,
      frameNumber: 3,
    },
    [Frames.CuratedMeatTypeSelectionFrame]: {
      totalFrames,
      frameNumber: 3,
    },
    [Frames.DealsFrame]: {
      totalFrames,
      frameNumber: 4,
    },
    [Frames.PaymentFrame]: {
      totalFrames: totalFrames,
      frameNumber: totalFrames,
    },
  }

  return pathname ? allFramesObj[pathname] : allFramesObj
}
