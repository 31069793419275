import { MinimalPlanTypeCardProps } from './MinimalPlanTypeCard'

export const MinimalPlanTypeCard: MinimalPlanTypeCardProps[] = [
  {
    typeDefinition: {
      isDefault: false,
      isVisible: true,
      description: `Choose specific cuts from any type of meat, including seafood. Two sizes available, each with 20% more meat than the Curated Plan.`,
      isDisabled: false,
      name: 'Custom Plan',
      type: 'cst',
      weight: '9 - 14 lbs',
    },
    perkList: [
      {
        status: 'included',
        perkDescription: '9 - 14 lbs of high quality meat',
      },
      { status: 'included', perkDescription: 'Free shipping, cancel anytime' },
      { status: 'included', perkDescription: 'Choose your type of meat' },
      {
        status: 'included',
        perkDescription: 'Choose your specific cuts of meat',
      },
      { status: 'included', perkDescription: 'Options for seafood & more' },
    ],
  },
  {
    typeDefinition: {
      isDefault: false,
      isVisible: true,
      description:
        'Choose your meat types & we’ll pick the specific cuts, in standard pack sizes. Two sizes available.',
      isDisabled: false,
      name: 'Curated Plan',
      type: 'beef_chicken_pork',
      weight: '8 - 11 lbs',
    },
    perkList: [
      {
        status: 'included',
        perkDescription: '8.5 - 11 lbs of high quality meat',
      },
      { status: 'included', perkDescription: 'Free shipping, cancel anytime' },
      { status: 'included', perkDescription: 'Choose your type of meat' },
      {
        status: 'not_included',
        perkDescription: 'Choose your specific cuts of meat',
      },
      { status: 'not_included', perkDescription: 'Options for seafood & more' },
    ],
  },
  {
    typeDefinition: {
      isDefault: true,
      isVisible: true,
      description: `Get our selection of beef, pork & chicken in smaller pack sizes. Good for smaller households or those who eat less meat.`,
      isDisabled: false,
      name: 'Basic Plan',
      type: 'basic_beef_chicken_pork',
      weight: '7 - 7.5 lbs',
    },
    perkList: [
      {
        status: 'included',
        perkDescription: '7 - 7.5 lbs of high quality meat',
      },
      { status: 'included', perkDescription: 'Free shipping, cancel anytime' },
      { status: 'not_included', perkDescription: 'Choose your type of meat' },
      {
        status: 'not_included',
        perkDescription: 'Choose your specific cuts of meat',
      },
      { status: 'not_included', perkDescription: 'Options for seafood & more' },
    ],
  },
]
