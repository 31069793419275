import React from 'react'
import { BoxTypeDefinition } from '~/bb-api/schemata'
import { MinimalPlanTypeCardUI, PerkList } from './MinimalPlanTypeCard.UI'

export interface MinimalPlanTypeCardProps {
  typeDefinition: BoxTypeDefinition
  perkList: PerkList
}

export const MinimalPlanTypeCard = ({
  typeDefinition,
  perkList,
}: MinimalPlanTypeCardProps) => {
  return (
    <MinimalPlanTypeCardUI
      perkList={perkList}
      typeDefinition={typeDefinition}
    />
  )
}
